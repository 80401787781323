import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//slices
import { openModal } from '../../../../../../store/slices/searchSlice';
//assets
import DocsIcon from '../../../../icons/docsIcon';
import PlayIcon from '../../../../icons/playIcon';
import EyeIcon from '../../../../icons/eyeIcon';
import UploadIcon from '../../../../icons/uploadIcon';
import BuildingIcon from '../../../../icons/buildingIcon';
//components
import TableOptionsMenu from '../../../../TableWidget/components/TableOptionsMenu';
//constants
import { MODAL_TYPES } from '../../../../../../constants';
import ShareScan from '../../../../TableWidget/components/TableView/components/ShareScan';


const OptionsMenu = React.memo(({ pid, subject, videoLink }) => {
    const dispatch = useDispatch();
    const menuItems = [
        {
            label: 'View baseline',
            icon: EyeIcon,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.VIDEO_MODAL, subject, videoLink, pid })),
        },
        {
            label: 'Upload move-out scan',
            icon: UploadIcon,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPLOADER_MODAL, pid })),
        },
        {
            label: 'View unit',
            icon: BuildingIcon,
            onClick: () => {}, 
            disabled: true,
        },
    ];
    
    return (
        <TableOptionsMenu items={menuItems} />
    );
});

const ActionsPanelMiMo = ({ pid, subject, ext_ref, latest_report_id, changeSetNotifState, videoLink, scan_id }) => {
    const dispatch = useDispatch();

    return (
        <>
            {latest_report_id ? (
                    <>
                        <span
                            className={`cursor-pointer`}
                            onClick={(e) => {
                            e.stopPropagation();
                            console.log("Calling openVideoModal", videoLink ? { subject, videoLink } : { pid, subject });
                            dispatch(openModal({ modalName: 'videoModal', subject, videoLink }));
                            }}
                            title={"View Scan"}
                        >
                            <PlayIcon />
                        </span>
                        <Link to={`/report/${latest_report_id}`} className="cursor-pointer" title={"View Report"}>
                            <DocsIcon />
                        </Link>
                    </>
                ) : (
                    <>
                        <span className='cursor-default'>
                            <PlayIcon disabled />
                        </span>
                        <span className='cursor-default'>
                            <DocsIcon disabled />
                        </span>
                    </>
                )
            }
            <ShareScan 
                pid={pid}
                subject={subject}
                changeSetNotifState={changeSetNotifState}
                scan_id={scan_id}
                ext_ref={ext_ref}
            />
            <OptionsMenu
                pid={pid}
                subject={subject}
                videoLink={videoLink}
            />
        </>
    )
}

export default ActionsPanelMiMo;