
import { postReqOptBuilder } from "./main_utils";


const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB chunks (adjust as needed)


const getFileExtFromBlob = (theBlob) => {
    return getFileExtFromBlobType(theBlob.type);
}


const getFileExtFromBlobType = (blobType) => {
    var fileExt = "";
    if (blobType.includes("video/webm")) {
        fileExt += ".webm";
    } else if (blobType.includes("video/mp4")) {
        fileExt += ".mp4";
    } else if (blobType.includes("video/x-matroska")) {
        fileExt += ".mkv";
    } else {
        fileExt += "." + blobType.split("video/")[1].split(";")[0];
    }
    return fileExt;
}


const blobToFile = (theBlob, fileName) => {
    var finalFileName = fileName + getFileExtFromBlob(theBlob);
    return new File([theBlob], finalFileName, {type: theBlob.type.split(";")[0]});
}


const fetchPresignedUrls = async (para_be, file_name, totalParts, file_type, file_id, retryCount=0) => {
    const data = {
        total_parts: totalParts,
        id: file_id,
        filename: file_name,
        filetype: file_type,
    };
    const onFail = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const d = fetchPresignedUrls(para_be, file_name, file_type, file_id, retryCount + 1);
                resolve(d);
            }, 5000 + (retryCount * 2000));
        });
    }

    try {
        const resp = await fetch(`${para_be}/media/generate_presigned_url`, postReqOptBuilder(data));
        const jsonResponse = await resp.json();
        console.log("Presigned URL response:", jsonResponse);
        if (jsonResponse.status === 200) {
            return {
                // upload_id: jsonResponse.result.upload_id,
                presigned_urls: jsonResponse.result.presign_urls,
            };
        } else {
            console.log("Failed generating presigned url");
            if (retryCount < 5) {
                return await onFail();
            } else return null;
        }
    } catch (error) {
        console.error("An error occurred:", error);
        if (retryCount < 5) {
            return await onFail();
        } else return null;
    }
};

const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

const uploadFileChunk = async (
    presignedUrl, fileType, fileSize, blob, uploadedChunks, 
    onRetryUpload, retryCount=0, setUploadRate=null
) => {
    if (retryCount > 0 && retryCount < 5) {
        if (retryCount === 1) onRetryUpload();
        console.log("Checking if there is a network connection");
        const testResp = await fetch("https://aiv2.paraspot.ai/", {credentials: "include"});
        if (testResp.status !== 200) {
            await delay(1000 + (retryCount * 1000));
            return await uploadFileChunk(
                presignedUrl, fileType, fileSize, blob, uploadedChunks, 
                onRetryUpload, retryCount + 1, setUploadRate
            );
        }
    } else if (retryCount === 5) {
        console.log("Failed to upload chunk after 5 retries");
        return false;
    }

    try {
        const startTime = performance.now();
        const response = await fetch(presignedUrl, {
            method: "PUT",
            headers: {
                "Content-Type": "application/octet-stream",
            },
            body: blob,
        });
        const endTime = performance.now();
        if (setUploadRate) {
            try {
                // Calculate upload Rate
                const timeTaken = (endTime - startTime) / 1000; // Convert to seconds
                const uploadRate = (blob.size / timeTaken) / 1e6; // Convert to MB/s
                setUploadRate(uploadRate);
            } catch (uploadRateError) {
                console.error("Failed to calculate upload rate:", uploadRateError);
            }
        }
        
        // if (response.status === 308) {
        if (response.ok) {
            uploadedChunks++;
            return true;
        } else {
            await delay(1000 + (retryCount * 1000));
            return await uploadFileChunk(
                presignedUrl, fileType, fileSize, blob, uploadedChunks, 
                onRetryUpload, retryCount + 1, setUploadRate
            );
        }
    } catch (error) {
        console.error("Chunk upload failed:", error);
        await delay(1000 + (retryCount * 1000));
        return await uploadFileChunk(
            presignedUrl, fileType, fileSize, blob, uploadedChunks, 
            onRetryUpload, retryCount + 1, setUploadRate
        );
    }
}


const getBlobFromList = async (blobList, index) => {
    const b = blobList[index];
    if (typeof b === "string") {
        return await getBlobFromIndexedDB(b)
    }
    return b;
}


const uploadFile = (
    // para_be, file, presigned_url, file_id, updatePercentage, setLoader, 
    para_be, recordedBlobs, fileName, fileType, fileSize, presigned_url, file_id, updatePercentage, setLoader, 
    setNotifState, onUploadComplete, onUploadFail, onRetryUpload, setUploadRate=null, uploadedChunks=null
) => {
    // const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);
    const totalChunks = recordedBlobs.length;
    const results = [];
    const BATCH_SIZE = 4; // 7;

    // Function to upload the file parts in batches based on the BATCH SIZE. 
    // Returns a list of true/false based on the success of the operation
    const processBatch = async (batch, _uploadedChunks) => {
        return Promise.all(
            // batch.map(async (presignedUrl, i) => {
            batch.map(async (batchItem, i) => {
                /*
                const chunkIndex = _uploadedChunks + i;
                const contentRangeStart = chunkIndex * CHUNK_SIZE;
                const contentRangeEnd = (chunkIndex + 1) * CHUNK_SIZE;
                const blob = file.slice(contentRangeStart, contentRangeEnd);
                const retVal = await uploadFileChunk(
                    presignedUrl, file.type, file.size, blob, _uploadedChunks,
                    contentRangeStart, contentRangeEnd,
                    onRetryUpload, 0, setUploadRate
                );
                */
                const { presignedUrl, blob } = batchItem;
                const retVal = await uploadFileChunk(
                    presignedUrl, fileType, fileSize, blob, _uploadedChunks,
                    onRetryUpload, 0, setUploadRate
                )
                if (retVal === true) updatePercentage((o) => o + Math.floor(1 / totalChunks) * 100);
                return retVal;
            })
        )
    }

    // Function to call the processBatch function in a loop until all chunks are uploaded
    const collectBatches = async () => {
        let failsCount = 0;
        let lastTimeOnline = new Date();
        while (results.length < totalChunks) {
            if (navigator.onLine) {
                lastTimeOnline = new Date();
                const batch = []
                for (let i = 0; i < BATCH_SIZE; i++) {
                    const chunkIndex = results.length + i;
                    if (chunkIndex >= totalChunks) break;
                    if (uploadedChunks && uploadedChunks.includes(chunkIndex)) continue;
                    const blob = await getBlobFromList(recordedBlobs, chunkIndex);
                    batch.push(
                        {presignedUrl: presigned_url[chunkIndex], blob}
                    );
                }
                const batchResults = await processBatch(batch, results.length);
                results.push(...(batchResults.filter((x) => x === true)));
                failsCount += batchResults.filter((x) => x === false).length;
                updatePercentage(Math.floor(results.length / totalChunks * 100));
    
                // If failed to upload parts of the video 10 times after all retries, stop the process
                if (failsCount > 10) {
                    console.error("Fail count passed 10. Failed to upload video");
                    results.push(...Array.from({ length: failsCount }, () => false));
                    return results;
                }
            } else {
                if (new Date() - lastTimeOnline >= 120000) {
                    setNotifState({
                        "type": "error", 
                        "msg": "Network is offline for too long. Stopping upload"
                    });
                    return [...results, false];
                } else {
                    setNotifState({
                        "type": "error", 
                        "msg": "Network is offline. Waiting for network to come back online..."
                    });
                    await delay(5000);
                }
            }
        }
        return results;
    }
    
    collectBatches()
    .then((res) => {
        const allTrue = res.every((x) => x === true);
        if (!allTrue) {
            const failedOnes = res.map((x, idx) => x !== true ? idx : null).filter((x) => x !== null);
            setLoader(false);
            onUploadFail();
            console.error("Failed to upload video");
            console.error("Failed on chunks:", failedOnes);
            setNotifState({"type": "error", "msg": "Failed to upload parts of the video"});
            return;
        }
        // After all chunks are uploaded, send a request to finalize the upload
        // fetch(`${para_be}/media/upload_video`, postReqOptBuilder({expectedSize: file.size, id: file_id, filename: file.name}))
        // fetch(`${para_be}/media/upload_video`, postReqOptBuilder({expectedSize: fileSize, id: file_id, filename: fileName}))
        fetch(`${para_be}/media/upload_video`, postReqOptBuilder({expectedSize: totalChunks, id: file_id, filename: fileName}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                try {
                    fetch(`${para_be}/scan/scanStarted`, postReqOptBuilder(
                        file_id.includes('_') ?
                            {pid: file_id.split("_")[1], scanType: 'checkout'} :
                            {pid: file_id, scanType: 'baseline'}
                    ))
                } catch (error) {
                    console.error("An error occurred while sending scanStarted notification:", error);
                }
                if (!file_id.includes('_')) {
                    try {
                        fetch(`${para_be}/scan/building_baseline`, postReqOptBuilder(
                                {uid: '', cid: '', pid: file_id}, 
                                true, {Authorization: document.cookie.split("AuthToken=")[1].split(";")[0]}
                        ))
                        .then(response2 => response2.json())
                        .then(response2 => {
                            if (setNotifState) {
                                if (response2.status === 200) {
                                    setNotifState({"type": "success", "msg": "Updated unit status"});
                                } else {
                                    setNotifState({"type": "error", "msg": "Failed to update unit status"});
                                }
                            }
                        })
                        .catch((x) => {
                            if (setNotifState) setNotifState({"type": "error", "msg": "Failed to update unit status"});
                        });
                    } catch (error) {
                        console.error("An error occurred while updating baseline status:", error);
                    }
                }
                
                setLoader(false);
                if (onUploadComplete) onUploadComplete();
            } else {
                setLoader(false);
                onUploadFail();
                console.error("Failed to upload video");
            }
        })
        .catch((error) => {
            // Handle any errors that occurred during the final steps
            setLoader(false);
            console.error("An error occurred:", error);
            onUploadFail();
        });
    })
    .catch((error) => {
        // Handle any errors that occurred during the final steps
        setLoader(false);
        console.error("An error occurred:", error);
        onUploadFail();
    });
};

const checkAvailableStorage = async (minRequiredSpace = 200, onNotEnoughStorage = null) => {
    if (navigator.storage && navigator.storage.estimate) {
        const { usage, quota } = await navigator.storage.estimate();
        const freeSpaceMB = (quota - usage) / 1024 / 1024;

        console.log(`Available storage: ${freeSpaceMB.toFixed(2)} MB`);
        if (freeSpaceMB < minRequiredSpace) {
            if (onNotEnoughStorage) onNotEnoughStorage();
            return {status: false, freeSpaceMB};
        }
        return {status: true, freeSpaceMB};
    }
    return {status: null, freeSpaceMB: null};
}

const checkAvailableRam = () => {
    if (performance.memory) {
        const { jsHeapSizeLimit, usedJSHeapSize } = performance.memory;
        const freeRamMB = (jsHeapSizeLimit - usedJSHeapSize) / 1024 / 1024;

        console.log(`Available RAM: ${freeRamMB.toFixed(2)} MB`);
        return freeRamMB;
    }
    return Infinity;
}

const storeBlobInIndexedDB = async (blob, key = "video") => {
    return new Promise((resolve, reject) => {
        interactWithIndexDB(
            (event) => { 
                const db = event.target.result;
                if (!db.objectStoreNames.contains("videos")) db.createObjectStore("videos"); 
            },
            () => reject("IndexedDB unavailable."),
            (event) => {
                const { transaction, store } = setupIndexDBStore(event, "readwrite");
                store.put(blob, key);
                transaction.oncomplete = () => resolve();
                transaction.onerror = () => reject("Failed to store.");
            }
        );
    });
}

const getBlobFromIndexedDB = async (key = "video") => {
    return new Promise((resolve, reject) => {
        interactWithIndexDB(
            null,
            () => reject("IndexedDB unavailable."),
            (event) => {
                const { store } = setupIndexDBStore(event, "readonly");
                const getRequest = store.get(key);
    
                getRequest.onsuccess = () => {
                    if (!getRequest.result) {
                        reject("No video found in IndexedDB.");
                    } else {
                        resolve(getRequest.result);
                    }
                };
    
                getRequest.onerror = () => reject("Failed to fetch Blob from IndexedDB.");
            }
        );
    });
}

const deleteBlobFromIndexedDB = async (key = "video") => {
    return new Promise((resolve, reject) => {
        interactWithIndexDB(
            null,
            () => reject("IndexedDB unavailable."),
            (event) => {
                const { store } = setupIndexDBStore(event, "readwrite");
                const deleteRequest = store.delete(key);
    
                deleteRequest.onsuccess = () => {
                    console.log("Blob deleted from IndexedDB.");
                    resolve();
                };
                deleteRequest.onerror = () => reject("Failed to delete Blob from IndexedDB.");
            }
        );
    });
}

const interactWithIndexDB = (onupgradeneeded=null, onerror=null, onsuccess=null) => {
    const request = indexedDB.open("VideoDB", 1);
    if (onerror) request.onerror = onerror;
    if (onupgradeneeded) request.onupgradeneeded = onupgradeneeded;
    if (onsuccess) request.onsuccess = onsuccess;
}

const setupIndexDBStore = (event, transactionType) => {
    const db = event.target.result;
    const transaction = db.transaction("videos", transactionType);
    return { db, transaction, store: transaction.objectStore("videos") }
}

async function requestPersistentStorage() {
    if (navigator.storage && navigator.storage.persist) {
        const granted = await navigator.storage.persist();
        console.log(granted ? "Persistent storage granted!" : "Persistent storage denied.");
        return granted;
    }
    return false;
}


export { 
    blobToFile, fetchPresignedUrls, uploadFile, getFileExtFromBlob, getFileExtFromBlobType,
    checkAvailableStorage, checkAvailableRam, requestPersistentStorage,
    storeBlobInIndexedDB, getBlobFromIndexedDB, deleteBlobFromIndexedDB, getBlobFromList
};
