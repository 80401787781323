
import ReactDOM from 'react-dom';


const renderPopupNewWindow = (title="Popup", PopupComponent, onFail=null, onSuccess=null) => {
    const popup = window.open('', '', 'width=500,height=600');

    if (!popup) {
        if (onFail) onFail("Something went wrong while opening the window. Please contact support.");
        return false;
    }

    popup.document.title = title;
    popup.document.body.innerHTML = '<div id="popup-root"></div>';

    const style = popup.document.createElement('style');
    style.innerHTML = `
        html, body {
            margin: 0;
            padding: 0;
        }
    `;
    popup.document.head.appendChild(style);

    const container = popup.document.getElementById('popup-root');

    ReactDOM.render(
        <PopupComponent
            onSubmit={(data) => {
                console.log("Received from popup:", data);
                popup.close();
                onSuccess(data);
            }}
            onFail={(msg=null) => {
                popup.close();
                if (onFail) onFail(msg);
            }}
        />,
        container
    );
};

export {
    renderPopupNewWindow
};