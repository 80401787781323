import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//components
import BaseModal from '../../../../../../components/Modal';
import Btn from "../../../../../../components/buttons/standard/btn";
//utils
import { postReqOptBuilder } from "../../../../../../utils/main_utils";
import { fetchPresignedUrls, uploadFile } from "../../../../../../utils/vid_upload_utils";
//slices
import { postLogEntry } from "../../../../../../store/slices/activitySlice";
//constants
import { para_be } from "../../../../../../config";
import { INSPECTION_TYPES } from "../../../../../../constants";
//assets
import wa_logo from "../../../../../../assets/icons/whatsapp-icon.png"


const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB chunks (adjust as needed)

const VideoUploaderModal = ({ open, handleClose, pid, setNotifState, changeBlockStatus, scanType=INSPECTION_TYPES.BASELINE }) => {
    const dispatch = useDispatch();
    const endUserData = useSelector(state => state.config.endUserData);

    const [isDragOver, setIsDragOver] = useState(false);
    const [uploadedFile, setUploadedFile] = useState("");
    const [chunck, setChunck] = useState(0); //progress bar data
    const [show, setShow] = useState(false); //show the progressbar
    const [loader, setLoader] = useState(false); //show the progressbar
    const [complete, setcomplete] = useState(false); // Indicates whether a process or task is complete
    
    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const file = e.dataTransfer.files[0];
        setUploadedFile(file);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
    };
    
    const hanldeUploadVideo = () => {
        if (uploadedFile) {
            setShow(true);
            changeBlockStatus(true);
            if (pid.includes('_')) {
                dispatch(postLogEntry( 
                    { 
                        activityID: `${pid.split('_')[0]}--${scanType}`, activityType: 'pid--inspectionType', 
                        ip: endUserData?.ip, action: {action: "upload-video-start", target: "scan"} 
                    }
                ));
            } else {
                dispatch(postLogEntry( 
                    { 
                        activityID: pid, activityType: 'pid', 
                        ip: endUserData?.ip, action: {action: "upload-video-start", target: "baseline"} 
                    }
                ));
            }
            const totalParts = Math.ceil(uploadedFile.size / CHUNK_SIZE);
            const recordedBlob = Array.from({ length: totalParts }, (_, i) => uploadedFile.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE));
        
            // fetchPresignedUrls(props.para_be, fileName, recordedBlob.chunks.length, fileType, `${scanID}_${inspection_type}_${pid}`)
            fetchPresignedUrls(para_be, uploadedFile.name, totalParts, uploadedFile.type, pid)
            .then(({ presigned_urls }) => {
                console.log({ presigned_urls });
                if (presigned_urls) {
                    return uploadFile(
                        // props.para_be, recordedBlob.chunks, fileName, fileType, recordedBlob.size, presigned_urls, `${scanID}_${inspection_type}_${pid}`, 
                        para_be, recordedBlob, uploadedFile.name, uploadedFile.type, uploadedFile.size, presigned_urls, pid, 
                        setChunck, setLoader, setNotifState, 
                        () => setcomplete(true), () => setShow(false), 
                        () => setNotifState({type: "info", message: "Re-trying to upload scan video"})
                    );
                } else {
                    throw new Error("Presigned URLs not available");
                }
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                setShow(false);
                changeBlockStatus(false);
            });
        }
    };
    
    const removeVideo = () => {
        setUploadedFile(null);
        setShow(false);
        changeBlockStatus(false);
        setChunck(0);
    };


    return (
        <>
            <BaseModal
                open={open}
                onClose={handleClose}
                minWidth={400}
                maxWidth={400}
            >
                <div className="flex flex-col items-center justify-center w-full mt-12 gap-5">
                    <div 
                        className={`w-[300px] border-2 border-dashed ${isDragOver ? "border-blue-500" : "border-gray-300"} flex items-center justify-center text-lg text-gray-800`} 
                        onDrop={handleDrop}
                    >
                        {uploadedFile ? 
                            <div className="flex items-center justify-center w-full h-full">
                                <video controls className="w-[300px] h-[300px]">
                                    <source src={URL.createObjectURL(uploadedFile)} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            :
                            <label className="text-center cursor-pointer">
                                <p className="m-0 p-2">
                                    {"Drag & drop a video file here or click to select a file"}
                                </p>
                                <input type="file" accept="video/*" onChange={handleFileChange} className="hidden"/>
                            </label>
                        }
                    </div>
                    {show &&
                        <>
                            {loader && 
                                <div className="text-center">
                                    <span>Processing</span>
                                    <br/>
                                    <span className="text-lg">It might take a few moments to start</span>
                                </div>
                            }
                            {complete &&
                                <h3 className="mb-0 text-2xl">
                                    Upload Complete
                                </h3>
                            }
                            <div className="w-full flex items-center justify-center">
                                <div className="border-2 border-gray-300 rounded-full w-5 h-5 animate-spin"></div>
                                <progress max="100" value={chunck} className="w-4/5 h-8 ml-4"></progress>
                                <p className="pl-4 text-xl">{chunck}%</p>
                            </div>
                        </>
                    }
                    {(uploadedFile && !show) &&
                        <div className="flex gap-2">
                            <Btn
                                text="Change Video"
                                type="secondary"
                                onclick={removeVideo}/>
                            <Btn
                                text="Upload Video"
                                type="primary"
                                onclick={hanldeUploadVideo}/>
                        </div>
                    }
                </div>
            </BaseModal>
            {/* {open && 
                <button 
                    className="fixed bottom-2 right-2 z-1000 flex items-center justify-center bg-white shadow-md rounded-full p-0"
                    onClick={() => window.open("https://wa.me/message/NMWJBUHZPRIHA1")}
                >
                    <div className="mt-3 mr-5">Need Help?</div>
                    <img src={wa_logo} className="h-12" alt="WhatsApp Logo"/>
                </button>
            } */}
        </>
    )
}

export default VideoUploaderModal;