import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//mui
import { Box, Typography } from '@mui/material';
//components
import Thumbnails from './Thumbnails';
import ImageDisplay from './ImageDisplay';
import EditControls from '../EditControls';
//slice
import { setCheckboxStates, setItemTitle, setItemDescription, handleCloseModal, setItemPrice } from '../../../../../../../store/slices/reportsSlice';
//utils
import { capitalizeString, postReqOptBuilder } from '../../../../../../../utils/main_utils';
import { dateTimeFormater } from '../../../../../../../utils/date_utils';
//constants
import { DATE_FORMAT, PROPERTY_ROLE } from '../../../../../../../constants';
import SelectableThumbnails from './SelectableThumbnails';
import CustomButton from '../../../../../../../components/buttons/CustomButton';
import { para_be } from '../../../../../../../config';


const ImageInspector = ({ images, currentImageIndex, handleImageChange, selectedImage }) => {
    const dispatch = useDispatch();
    const { isMobile } = useSelector(state => state.config);
    const {
        report_id,
        checkboxStates,
        tabView,
        mediaData,
        itemMetadata,
        editExportMode,
        previewMode,
        exportReport
    } = useSelector((state) => state.reports);

    const finalSubject = itemMetadata?.[tabView]?.[selectedImage.object_id]?.title ? itemMetadata[tabView][selectedImage.object_id].title : (images[currentImageIndex]?.subject ? capitalizeString(images[currentImageIndex]?.subject.replace(/_/g, " "), true) : images[currentImageIndex]?.issue_type);
    const finalLabel = images[currentImageIndex]?.label ? capitalizeString(images[currentImageIndex]?.label.replace(/_/g, " "), true) : images[currentImageIndex]?.issue_type;
    
    const hasBeforeImg = (
        !images[currentImageIndex]?.issue_type ? null :
        images[currentImageIndex].issue_type === 'Defect' ? images[currentImageIndex]?.before_media : images[currentImageIndex]?.media
    );
    const hasAfterImg = (
        !images[currentImageIndex]?.issue_type ? null :
        images[currentImageIndex].issue_type === 'Defect' ? images[currentImageIndex]?.media : images[currentImageIndex]?.before_media
    );
    
    const [isChecked, setIsChecked] = useState(checkboxStates?.[tabView]?.[selectedImage.object_id] || false);
    const [description, setDescriptionText] = useState(itemMetadata?.[tabView]?.[selectedImage.object_id] || '');
    const [price, setPrice] = useState(itemMetadata?.[tabView]?.[selectedImage.object_id]?.price || '');
    const [title, setTitle] = useState(finalSubject || '');
    const [selectedUpdateFrame, setSelectedUpdateFrame] = useState(null);
    const [editImgMode, setEditImgMode] = useState(false);
    const [loadingChangeImg, setLoadingChangeImg] = useState(false);
    
    
    const handleSave = () => {
        dispatch(setCheckboxStates({ object_id: selectedImage.object_id, checked: isChecked }));
        dispatch(setItemTitle({ object_id: selectedImage.object_id, title })); // Update description
        dispatch(setItemDescription({ object_id: selectedImage.object_id, description })); // Update description
        dispatch(setItemPrice({ object_id: selectedImage.object_id, price })); // Update price
        dispatch(handleCloseModal());
    };

    const updateFrame = () => {
        setLoadingChangeImg(true);
        fetch(
            para_be + "/reports/change_before_pic", postReqOptBuilder(
                {
                    report_id: report_id, 
                    itemID: selectedImage.object_id, 
                    viewIdx: currentImageIndex, 
                    new_pic_index: selectedUpdateFrame.split("idx=")[1].split("_")[0], 
                }
            )
        )
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 200) {
                document.getElementById("beforeImg-modal").src = selectedUpdateFrame;
                setLoadingChangeImg(false);
                setEditImgMode(false);
            }
        });
    }

    const extraDetailsSection = () => {
        return (
            (
                !previewMode && (selectedImage?.repair_cost_json || 
                (!editExportMode && selectedImage?.description))
            ) &&
                <div className='mt-4 mb-4 mr-6'>
                    {(!editExportMode && selectedImage?.description) &&
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: '#0C1222',
                                marginBottom: '8px',
                            }}
                        >
                            {selectedImage?.description}
                        </Typography>
                    }
                    {selectedImage?.repair_cost_json &&
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: 400,
                                color: '#0C1222',
                                opacity: 0.56,
                            }}
                        >
                            Est. Repair Cost: {selectedImage?.repair_cost_json?.currency_sign}{selectedImage?.repair_cost_json?.min} - {selectedImage?.repair_cost_json?.currency_sign}{selectedImage?.repair_cost_json?.max}
                            <br/>
                            {selectedImage?.repair_cost_json?.description}
                        </Typography>
                    }
                </div>
        );
    }

    useEffect(() => {
        setSelectedUpdateFrame(null);
    }, [editImgMode]);
    
    useEffect(() => {
        if (selectedImage) {
            setIsChecked(checkboxStates?.[tabView]?.[selectedImage.object_id] || false);
            setDescriptionText(itemMetadata?.[tabView]?.[selectedImage.object_id]?.description || '');
            setPrice(itemMetadata?.[tabView]?.[selectedImage.object_id]?.price || '');
        }
    }, [selectedImage]);
    
    
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column-reverse' : 'row-reverse',
                height: '100%',
            }}
        >
            {/* Thumbnails and Labels */}
            <div className="flex flex-col mt-2" style={{ minWidth: '300px' }}>
                {editImgMode ? (
                    <>
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#0C1222',
                                fontSize: '20px',
                                lineHeight: '26px',
                            }}
                        >
                            {finalLabel ?? "No Label"}
                        </Typography>

                        {extraDetailsSection()}

                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: '#0C1222',
                                fontSize: '14px',
                                fontWeight: 600,
                                lineHeight: '130%',
                                marginBottom: '12px',
                                marginTop: '16px',
                            }}
                        >
                            Update Before Image
                        </Typography>
                        {/* Thumbnails component */}
                        <SelectableThumbnails
                            images={Array.from({ length: mediaData.available_frames }, (_, idx) => "https://aiv2.paraspot.ai/cva/presentImage/UniqueFrames_" + mediaData.ts + "?idx=" + idx + "_orig_size&lt=1")}
                            isMobile={isMobile}
                            onSelect={(img) => { setSelectedUpdateFrame(img); }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                bottom: '0',
                                right: '8px',
                            }}
                        >
                            <CustomButton
                                variant="contained"
                                sx={{
                                    backgroundColor: '#F2F2F3',
                                    color: '#0C1222',
                                    fontSize: '12px',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    padding: '9px 15px',
                                    marginRight: '12px',
                                    boxShadow: '0 0 20px 0px rgba(0, 0, 0, 0.40)',
                                    border: '1px solid #E0E0E0',
                                    '&:hover': {
                                        backgroundColor: '#E0E0E0',
                                    }
                                }}
                                onClick={() => setEditImgMode(false)}
                            >
                                Cancel
                            </CustomButton>
                            <CustomButton
                                variant="contained"
                                sx={{
                                    backgroundColor: '#4F46E5', 
                                    color: 'white',
                                    fontSize: '12px',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    padding: '9px 15px',
                                    boxShadow: '0 0 20px 0px rgba(0, 0, 0, 0.40)',
                                    '&:hover': { backgroundColor: '#4338CA' } 
                                }}
                                loading={loadingChangeImg}
                                onClick={updateFrame}
                            >
                                Submit
                            </CustomButton>
                        </Box>
                    </>
                ) : (
                    <>
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#0C1222',
                                fontSize: '20px',
                                lineHeight: '26px',
                            }}
                        >
                            {finalLabel ?? "No Label"}
                        </Typography>

                        {extraDetailsSection()}
                        
                        {/* viewpoints label */}
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: '#5C6270',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '130%',
                                marginBottom: '8px',
                                marginTop: '16px',
                            }}
                        >
                            Additional Viewpoints
                        </Typography>
                        
                        {/* Thumbnails component */}
                        <Thumbnails
                            images={images}
                            currentImageIndex={currentImageIndex}
                            handleImageChange={handleImageChange}
                            isMobile={isMobile}
                        />
                        
                        {/* EditControls */}
                        {editExportMode && (
                            <EditControls
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                title={title}
                                setTitle={setTitle}
                                description={description}
                                setDescriptionText={setDescriptionText}
                                price={price}
                                setPrice={setPrice}
                                handleSave={handleSave}
                                exportReport={exportReport}
                                autoGen={
                                    selectedImage?.description ? {
                                        description: selectedImage?.description,
                                        repair_cost_json: selectedImage?.repair_cost_json
                                    } : null
                                }
                            />
                        )}
                        {previewMode &&
                            <div className='mt-4'>
                                {itemMetadata[tabView][selectedImage.object_id]?.title &&
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            color: '#0C1222',
                                            marginTop: '12px',
                                            marginBottom: '4px',
                                        }}
                                    >
                                        {itemMetadata[tabView][selectedImage.object_id]?.title}
                                    </Typography>
                                }
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        color: '#777A83',
                                    }}
                                >
                                    {itemMetadata[tabView][selectedImage.object_id]?.description || ''}
                                </Typography>
                                {(!exportReport.currentExportReport?.exported_data?.excludeCost && exportReport.propertyRole === PROPERTY_ROLE.RESIDENT) &&
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            color: '#777A83',
                                        }}
                                    >
                                        Cost: {exportReport.currentExportReport?.exported_data?.currency === 'eur' ? '€' : '$'}{Number((itemMetadata[tabView][selectedImage.object_id]?.price && itemMetadata[tabView][selectedImage.object_id]?.price !== "") ? itemMetadata[tabView][selectedImage.object_id]?.price : 0).toLocaleString('en-US')}
                                    </Typography>
                                }
                            </div>
                        }
                    </>
                )}
            </div>
            
            {/* Main Image Display */}
            <Box 
                sx={{ 
                    // flex: 1, 
                    display: 'flex', 
                    // alignItems: 'center', 
                    minWidth: isMobile ? '' : '550px',
                }}
            >
                <Box 
                    sx={{ 
                        // flex: 1, 
                        display: 'flex', 
                        // justifyContent: 'center',
                        // alignItems: 'center', 
                        width: '100%' 
                    }}
                >
                    {/* Before Image */}
                    {hasBeforeImg &&
                        <ImageDisplay
                            imgId="beforeImg-modal"
                            title={"Before" + (images[currentImageIndex].before_date ? ` (${dateTimeFormater(images[currentImageIndex].before_date, DATE_FORMAT)})` : "")}
                            src={
                                (editImgMode && selectedUpdateFrame) ? 
                                selectedUpdateFrame :
                                images[currentImageIndex].issue_type === 'Defect' ? images[currentImageIndex]?.before_media : images[currentImageIndex]?.media
                            }
                            alt={"Before" + (images[currentImageIndex].before_date ? ` (${dateTimeFormater(images[currentImageIndex].before_date, DATE_FORMAT)})` : "")}
                            isMobile={isMobile}
                            showTitle={hasAfterImg ? true : false}
                            toggleImgChangeMode={(images[currentImageIndex].issue_type === 'Defect' && !previewMode) ? () => setEditImgMode(!editImgMode) : null}
                        />
                    }
                    
                    {/* After Image */}
                    {hasAfterImg &&
                        <ImageDisplay
                            imgId="afterImg-modal"
                            title="After"
                            src={images[currentImageIndex].issue_type === 'Defect' ? images[currentImageIndex]?.media : images[currentImageIndex]?.before_media}
                            alt="After"
                            isMobile={isMobile}
                            showTitle={hasBeforeImg ? true : false}
                        />
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default ImageInspector;