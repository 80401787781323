import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
//mui
import { Dialog, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
//components
import ImageInspector from './components/ImageInspector';
import ArrowButton from './components/ArrowButton';
import CircularProgress from '../../../../../components/loaders/CircularLoader';
//slice
import { handleCloseModal,setSelectedImageIndex } from '../../../../../store/slices/reportsSlice';


// Fetch thumbnails for the selected image
const fetchThumbnails = async (selectedImage) => {
    if (selectedImage && !Array.isArray(selectedImage)) {
        try {
            const response = await axios.get(
                selectedImage['media']?.replace("getObjectFromScan", "getObjectViewsFromScan"),
                { withCredentials: true }
            );
            const numberOfImages = response.data.result || 0;
            
            return [...Array(numberOfImages).keys()].map((index) => ({
                before_date: selectedImage['before_date'] ? selectedImage['before_date'].split("-").reverse().join("-") : null,
                before_media: selectedImage['before_media']
                    ? `${selectedImage['before_media']}&view_idx=${index}&zoom_out=true`
                    : null,
                media: selectedImage['media']
                    ? `${selectedImage['media']}&view_idx=${index}&zoom_out=true`
                    : null,
                subject: selectedImage['subject'] ?? null,
                label: selectedImage['label'] ?? null,
                issue_type: selectedImage['issue_type'] ?? null,
                category: selectedImage['category'] ?? null,
                description: selectedImage['description'] ?? null,
                hazard_level: selectedImage['hazard_level'] ?? null,
                repair_cost: selectedImage['repair_cost'] ?? null,
                repair_cost_json: selectedImage['repair_cost_json'] ?? null,
                room: selectedImage['room'] ?? null,
            }));
        } catch (error) {
            throw new Error('Failed to fetch images');
        }
    } else {
        return [{
            before_media: selectedImage[0] ?? null,
            media: selectedImage[1] ?? null,
            label: ''
        }];
    }
};


const ImageViewerModal = ({ assetItems }) => {
    const dispatch = useDispatch();
    const { selectedImageIndex, imageViewerModal } = useSelector((state) => state.reports);
    const isMobile = useSelector((state) => state.config?.isMobile);
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const selectedImage = assetItems[selectedImageIndex];
    
    // Query to fetch images
    const { data: images = [], isLoading: loading } = useQuery(
        ['thumbnails', selectedImage],
        () => fetchThumbnails(selectedImage),
        {
            onError: () => console.error('Failed to fetch images'),
            staleTime: 300000
        }
    );
    
    const hasMultipleItems = assetItems.length > 1;
    
    const handleItemChange = (direction) => {
        // let newIndex;
        // if (previewMode) {
        //     const filteredAssets = assetItems.filter((item) => checkboxStates[item.object_id]);
        //     const filteredImIndex = filteredAssets.findIndex((item) => item.object_id === selectedImage.object_id);
        //     if (filteredImIndex === -1) return;
        //     const newSelection = filteredAssets[filteredImIndex + direction];
        //     if (!newSelection) return;
        //     newIndex = assetItems.findIndex((item) => item.object_id === newSelection.object_id);
        // } else {
        //     newIndex = selectedImageIndex + direction;
        // }
        const newIndex = selectedImageIndex + direction;
        if (newIndex >= 0 && newIndex < assetItems.length) {
            dispatch(setSelectedImageIndex(newIndex));
            setCurrentImageIndex(0);
        }
    };


    return (
        <Dialog
            open={imageViewerModal}
            onClose={() => dispatch(handleCloseModal())}
            // disableScrollLock
            fullScreen
            slotProps={{
                container: {
                    sx: {
                        height: 'auto', // Set this to 'auto' or any specific value you prefer
                    },
                },
            }}
            PaperProps={{
                sx: {
                    width: '60v',
                    maxWidth: isMobile ? '100vw' : '1000px',
                    height: isMobile ? '100vh' : '70vh',
                    maxHeight: '800px',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    backgroundColor: 'rgba(255, 255, 255)',
                    boxShadow: 24,
                    position: 'relative',
                },
            }}
        >
            <IconButton
                onClick={() => dispatch(handleCloseModal())}
                sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 2,
                    color: 'black',
                }}
            >
                <CloseIcon />
            </IconButton>
            
            <DialogContent
                sx={{
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {loading ? (
                            <CircularProgress size={50} />
                        ) : (
                            <>
                                {/* Left Arrow (Previous Asset) */}
                                <ArrowButton
                                    direction="left"
                                    onClick={() => handleItemChange(-1)}
                                    isVisible={selectedImageIndex > 0 && hasMultipleItems}
                                />
                                
                                {/* Main Image and Thumbnails */}
                                <ImageInspector
                                    selectedImage={selectedImage}
                                    images={images}
                                    currentImageIndex={currentImageIndex}
                                    handleImageChange={setCurrentImageIndex}
                                    
                                />
                                
                                {/* Right Arrow (Next Asset) */}
                                <ArrowButton
                                    direction="right"
                                    onClick={() => handleItemChange(1)}
                                    isVisible={selectedImageIndex < assetItems.length - 1 && hasMultipleItems}
                                />
                            </>
                        )
                    }
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ImageViewerModal;