import React from "react";

//style
import "./lobby.css";
//components
//containers
//assets
import appLogo from "../../../assets/icons/smartScan_logo.svg";
import dmParaspot from "../../../assets/icons/logo-darkMode.svg";
import Btn from "../../buttons/standard/btn";

export default function Lobby(props) {
    const {includeClientLogo, clientLogo, btnText, onNext, onHelp} = props;

    return (
        <div className={`sc-lobby${includeClientLogo ? " incl-cl" : ""}`}>
            <div className="lobby-content">
                {!includeClientLogo || !clientLogo ? "" : (
                    <div className="client-logo img-wrapper">
                        <img src={clientLogo} alt="Client Logo"/>
                    </div>
                )}
                <div className="app-logo img-wrapper">
                    <img src={appLogo} alt="App-logo"/>
                </div>
                {props.langSelection ?
                <>
                    <Btn type="primary" text="English" onclick={(e) => onNext("en")} />
                    <Btn type="primary" text="Spanish" onclick={(e) => onNext("es")} />
                </> : <Btn type="primary" text={btnText} onclick={(e) => onNext()} />
                }
                <div className="powered-by-wrapper">
                    <div>
                        {/* <div className="help-btn text-1-2" onClick={(e) => onHelp()}>Something went wrong? Click Here</div> */}
                        <Btn type="secondary" extraClasses="help-btn text-0" text="Need Help? Click Here" onclick={(e) => onHelp()} />
                        <div className="powered-by">
                            <span>Powered by</span>
                            <img src={dmParaspot} alt="dark-logo"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}