import React, { useEffect, useState } from 'react';
import { postReqOptBuilder } from '../../../../../utils/main_utils';


const AppFolioLogin = ({ onSubmit, onFail }) => {
  const [form, setForm] = useState({ email: '', password: '', mfa_phone: '+16506681895' });
  const [showPassword, setShowPassword] = useState(false);
  const [loginToken, setLoginToken] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    fetch(
        "https://production.integuru.ai/initialize-token", 
        {method: 'GET', headers: {"INTEGURU-SECRET": "E9988C7F7C2BF8B2FC2CF2DB678CC"}}
    )
    .then((response) => response.json())
    .then((data) => {
        setLoginToken(data.access_token);
    })
    .catch((error) => {
        console.log("ERROR:", error);
        onFail("Failed to load the AppFolio login form. Please try again later or contact support.");
    })
  }, []);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    
    if (form.email === "" || form.password === "") {
        setError("Please enter your email and password.");
        setLoading(false);
        return;
    } else if (!form.email.includes("@")) {
        setError("Please enter a valid email address.");
        setLoading(false);
        return;
    }
    
    fetch(
        "https://production.integuru.ai/appfolio/credentials", 
        postReqOptBuilder(form, false, {"INTEGURU-TOKEN": loginToken, "Content-Type": "application/json"}, true)
    )
    .then((response) => {
        console.log("RESPONSE:", response);
        if (response.status === 200) {
            return response.json();
        } else if (response.status === 500) {
            throw new Error(response.json());
        } else {
            setError("Invalid email or password");
            setLoading(false);
        }
    })
    .then((response) => {
        if (response?.success) {
            setLoading(false);
            onSubmit(form); // Send data back to parent
        } else {
            setError("Invalid email or password");
            setLoading(false);
        }
    })
    .catch((error) => {
        console.log("ERROR:", error);
        setError("An unexpected error occurred.\nPlease try again later or contact support.");
        setLoading(false);
    });
  };

  return (
    <div style={styles.container}>
        <div style={styles.logoWrapper}>
            <img src="https://public.cdn.appfolio.com/public/images/apm-secondary-white.svg" alt='AppFolio' style={styles.logo} />
        </div>

        <div style={styles.formWrapper}>
            <h2 style={styles.title}>Sign In</h2>

            {error && <div style={styles.errorBox}>{error}</div>}

            <form onSubmit={handleSubmit}>
                <label style={styles.label}>
                    Email
                    <input
                        type="email"
                        name="email"
                        required
                        value={form.email}
                        placeholder='john.smith@email.com'
                        onChange={handleChange}
                        style={styles.input}
                    />
                </label>
                
                <label style={styles.label}>
                    Password
                    <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        required
                        value={form.password}
                        onChange={handleChange}
                        style={styles.input}
                    />
                </label>
                
                <label style={styles.checkboxLabel}>
                    <input
                        type="checkbox"
                        onChange={(e) => setShowPassword(e.target.checked)}
                        style={{ marginRight: '6px' }}
                    />
                    Show password
                </label>

                <button type="submit" style={styles.submitButton}>
                    {loading ? "Loading..." : "Sign In"}
                </button>
            </form>
        </div>
    </div>
  );
};


const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f2f3f4',
        minHeight: '100vh',
        // padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        width: '100%',
        padding: '1.5rem 7.5rem',
        backgroundColor: '#006eb7'
    },
    logo: {
        width: '40vw',
        maxWidth: '300px',
        padding: '1.5rem 0',
        verticalAlign: 'middle',
    },
    formWrapper: {
        // backgroundColor: '#ffffff',
        borderRadius: '6px',
        padding: '30px 1.5rem',
        width: '100%',
        maxWidth: '400px',
        // boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    },
    title: {
        marginBottom: '20px',
        fontSize: '22px',
        fontWeight: '400',
    },
    label: {
        display: 'block',
        marginBottom: '15px',
        fontSize: '14px',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginTop: '6px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '14px',
    },
    checkboxLabel: {
        fontSize: '13px',
        marginBottom: '15px',
        display: 'block',
    },
    submitButton: {
        width: '100%',
        padding: '12px',
        backgroundColor: '#006eb7',
        color: '#ffffff',
        border: 'none',
        borderRadius: '4px',
        fontSize: '15px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#005e9c'
        }
    },
    errorBox: {
        backgroundColor: '#f8d7da',
        color: '#721c24',
        padding: '12px',
        borderRadius: '4px',
        marginBottom: '16px',
        fontSize: '14px',
        border: '1px solid #f5c6cb',
    },
};


export default AppFolioLogin;