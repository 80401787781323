import { useState, useEffect, useCallback } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
//components
import AssetOverview from '../components/AssetOverview';
import DropdownWithCheckboxes from '../../../components/dropdowns/muiSelect/muiMultiSelect';
import EmptyState from '../components/EmptyState';
//constants
import { ReportsCategory } from '../../../constants';
import { setSelectedCategories, updateFilteredData } from '../../../store/slices/reportsSlice';


const useReportSharedLogic = () => {
    const dispatch = useDispatch();

    const { 
        filteredData, inventoryCategories, roomsCategories, hasBaseline, 
        roomsData, checkboxStates, descriptions, selectedCategories 
    } = useSelector((state) => state.reports);
    
    const [tabIndex, setTabIndex] = useState('1');
    const [notifState, setNotifState] = useState(null);

    const handleNotifState = (notification) => {
        setNotifState(notification);
    };

    const handleChangeTabIndex = (event, newValue) => {
        setTabIndex(newValue);
        dispatch(updateFilteredData(newValue === "1" ? ReportsCategory.INVENTORY : newValue === "2" ? ReportsCategory.DEFECTS : ReportsCategory.ORGANIZATION));
    };

    const renderTabContent = useCallback((dataKey) => {
        if (filteredData && filteredData.length === 0) {
            return (
                <div className='flex flex-col'>
                    <EmptyState />
                </div>
            );
        }
        const usedCategories = (
                (roomsData || []).length === 0 || 
                (hasBaseline && [ReportsCategory.INVENTORY, ReportsCategory.ORGANIZATION].includes(dataKey))
            ) ? inventoryCategories : roomsCategories[dataKey];
        const usingCategories = dataKey === ReportsCategory.INVENTORY || (roomsData || []).length > 0;


        return (
            <div className='flex flex-col'>
                {usingCategories && (
                    <div className='flex w-52 my-4'>
                        <DropdownWithCheckboxes
                            options={Object.keys(usedCategories).filter((k) => usedCategories[k].length > 0).reduce((acc, category) => ({...acc, [category]: {primary: category}}), {})}
                            onChange={(selected) => { 
                                dispatch(setSelectedCategories(selected));
                                dispatch(updateFilteredData(ReportsCategory.INVENTORY));
                            }}
                            label="All Categories"
                            scrollable={true}
                            searchable={true}
                        />
                    </div>
                )}
                {usingCategories ? 
                    ((filteredData && usedCategories) && (
                        Object.entries(usedCategories).filter(
                            ([category, items]) => selectedCategories === null || selectedCategories.length === 0 || selectedCategories.includes(category)
                        ).map(([category, items]) => (
                            <AssetOverview
                                assetItems={filteredData.filter((item) => items.includes(item.object_id))}
                                assetItemsIndexes={
                                    filteredData
                                    .map((item, index) => [item.object_id, index])
                                    .filter(([object_id, idx]) => items.includes(object_id))
                                    .map(([object_id, idx]) => idx)
                                }
                                header={category}
                                checkboxStates={checkboxStates}
                                descriptions={descriptions}
                                setNotifState={setNotifState}
                            />
                        ))
                    ))
                    :
                    (filteredData &&
                        <AssetOverview
                            assetItems={filteredData}
                            assetItemsIndexes={filteredData.map((_, index) => index)}
                            header={"All Items"}
                            checkboxStates={checkboxStates}
                            descriptions={descriptions}
                            setNotifState={setNotifState}
                        />
                    )
                }
            </div>
        );
    }, [dispatch, filteredData, inventoryCategories, selectedCategories, checkboxStates, descriptions]);

    return {
        tabIndex,
        notifState,
        handleNotifState,
        handleChangeTabIndex,
        renderTabContent
    };
};

export default useReportSharedLogic;