import React from 'react';
//mui
import { Box, Button, TextField, Typography } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
//components
import Checkbox from '../../../../../../../components/Checkbox';
//constants
import { PROPERTY_ROLE } from '../../../../../../../constants';


const EditControls = ({ isChecked, setIsChecked, title, setTitle, description, setDescriptionText, price, setPrice, handleSave, exportReport, autoGen }) => {
    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            {/* Checkbox */}
            <div className='flexRow justify-between mb-2'>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <Checkbox
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                        sx={{
                            height: '17px',
                            width: '17px',
                            padding: '0px',
                            "& .MuiSvgIcon-root": {
                                width: "17px",
                                height: "17px",
                            }
                        }}
                    />
                    <Typography sx={{ fontSize: '14px', color: '#0C1222', lineHeight: '130%'}}>Add to export</Typography>
                </Box>
                {autoGen &&
                    <Button
                        variant="contained"
                        startIcon={<AutoAwesomeIcon sx={{ width: 20, height: 20, color: "#4F46E5" }} />}  
                        onClick={() => {
                            setDescriptionText(autoGen?.description || '');
                            setPrice(autoGen?.repair_cost_json?.min || '');
                        }}
                        sx={{
                            backgroundColor: 'transparent', 
                            color: '#4F46E5',
                            fontSize: '12px',
                            textTransform: 'none',
                            borderRadius: '8px',
                            padding: '6px 12px',
                            width: 'fit-content',
                            marginLeft: 'auto',
                            '&:hover': {
                                color: '#4338CA',
                                backgroundColor: 'transparent'
                            } 
                        }}
                    >
                        Auto-Generate
                    </Button>
                }
            </div>

            {/* Title */}
            <TextField
                label="Title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Update title for this item"
                fullWidth
                variant="outlined"
                sx={{
                    mt: 1,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#E0E0E0', 
                        },
                        '&:hover fieldset': {
                            borderColor: '#BDBDBD', 
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#4F46E5', 
                        },
                    },
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                        padding: '10px 14px',
                    },
                    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                        fontSize: '14px',
                        marginTop: '-6px',
                    }
                }}
            />
            
            {/* Description Input */}
            <TextField
                label="Comment"
                value={description}
                onChange={(e) => setDescriptionText(e.target.value)}
                placeholder="Add a comment for this item"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                sx={{
                    mt: 2,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#E0E0E0', 
                        },
                        '&:hover fieldset': {
                            borderColor: '#BDBDBD', 
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#4F46E5', 
                        },
                    },
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                    },
                }}
            />
            
            {/* Price Input */}
            {exportReport.propertyRole === PROPERTY_ROLE.RESIDENT && 
                <TextField
                    label="Cost"
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Set a cost for this item"
                    fullWidth
                    variant="outlined"
                    sx={{
                        mt: 2,
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#E0E0E0', 
                            },
                            '&:hover fieldset': {
                                borderColor: '#BDBDBD', 
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#4F46E5', 
                            },
                        },
                        '& .MuiInputBase-input': {
                            fontSize: '14px',
                            padding: '10px 14px',
                        },
                        '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                            fontSize: '14px',
                            marginTop: '-6px',
                        }
                    }}
                />
            }
            
            {/* Save Button */}
            <Box sx={{ display: 'flex', mt: 3 }}>
                <Button
                    onClick={handleSave}
                    sx={{
                        backgroundColor: '#4F46E5',
                        color: '#FFFFFF',
                        fontWeight: 700,
                        fontSize: '12px',
                        textTransform: 'none',
                        borderRadius: '4px',
                        padding: '6px 12px',
                        marginBottom: '8px',
                        '&:hover': {
                            backgroundColor: '#3C3EB8',
                        }
                    }}
                >
                    Save
                </Button>
            </Box>
        </Box>
    );
};

export default EditControls;