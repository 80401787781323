import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../../axios/axios';  
import { para_be } from '../../config';
import { ReportsCategory, InventoryCategory, PROPERTY_ROLE, DATETIME_FORMAT } from '../../constants';
import { dateObjFormatter } from '../../utils/date_utils';
const res = {
    "msg": "OK",
    "result": [
        "Shay13-10-24",
        "13/10/24 05:10 UTC",
        [
            "Note: This report was not matched to any previous scan, but only shows our detections from a single scan",
            "Found 5 detections labeled as Inventory",
            "Found 1 detections labeled as Defects"
        ],
        [],
        "https://aiv2.paraspot.ai/inspection/scan/media/101320240509391728796179.webm",
        {
            "defects": [
                {
                    "issue_type": "Defect",
                    "media": "https://aiv2.paraspot.ai/cva/v2/getObjectFromScan?src_id=131024051001EmhuEybZGictXFns&from=tenant&uid=9",
                    "object_id": "9",
                    "subject": "Defect"
                }
            ],
            "inventory": [
                {
                    "category": "Mirrors, Windows & Doors",
                    "issue_type": "Inventory",
                    "label": "ade_windowpane",
                    "media": "https://aiv2.paraspot.ai/cva/v2/getObjectFromScan?src_id=131024051001EmhuEybZGictXFns&from=tenant&uid=6",
                    "object_id": "6",
                    "subject": "Windowpane"
                },
                {
                    "category": "Mirrors, Windows & Doors",
                    "issue_type": "Inventory",
                    "label": "ade_door",
                    "media": "https://aiv2.paraspot.ai/cva/v2/getObjectFromScan?src_id=131024051001EmhuEybZGictXFns&from=tenant&uid=14",
                    "object_id": "14",
                    "subject": "Door"
                },
                {
                    "category": "Sitting Furniture",
                    "issue_type": "Inventory",
                    "label": "m4_chair",
                    "media": "https://aiv2.paraspot.ai/cva/v2/getObjectFromScan?src_id=131024051001EmhuEybZGictXFns&from=tenant&uid=31",
                    "object_id": "31",
                    "subject": "Chair"
                },
                {
                    "category": "Other",
                    "issue_type": "Inventory",
                    "label": "ade_painting",
                    "media": "https://aiv2.paraspot.ai/cva/v2/getObjectFromScan?src_id=131024051001EmhuEybZGictXFns&from=tenant&uid=43",
                    "object_id": "43",
                    "subject": "Painting"
                },
                {
                    "category": "Lights & Power Outlets",
                    "issue_type": "Inventory",
                    "label": "Converter",
                    "media": "https://aiv2.paraspot.ai/cva/v2/getObjectFromScan?src_id=131024051001EmhuEybZGictXFns&from=tenant&uid=47",
                    "object_id": "47",
                    "subject": "Converter"
                }
            ]
        },
        "123456789131024041253aYCvOWFgsvghqo2w",
        {
            "kitchen_view": {
                "after": "101320240509391728796179",
                "before": "",
                "before_date": null,
                "indexes": []
            },
            "meter_view": {
                "after": "101320240509391728796179",
                "before": "",
                "before_date": null,
                "indexes": []
            }
        },
        {},
        null,
        "checkin",
        true,
        {
            "Appliances": [],
            "Bath, Sinks & Toilets": [],
            "Bed & Couch": [],
            "Household Items": [],
            "Kitchenware": [],
            "Lights & Power Outlets": [
                "47"
            ],
            "Mirrors, Windows & Doors": [
                "6",
                "14"
            ],
            "Other": [
                "43"
            ],
            "Other Electronic Devices": [],
            "Plants": [],
            "Sitting Furniture": [
                "31"
            ],
            "Tables & Cabinets": []
        },
        {
            "available_frames": 1,
            "ts": "101320240509181728796158"
        }
    ],
    "status": 200
};

const groupByCategory = (data, categoriesDist) => {
    // const result = { [InventoryCategory.ALL]: [] }; 
    const result = {}; 
    data.forEach((item) => {
        const category = item.category ? item.category.replace(/\s+/g, '') : 'Uncategorized';
        if (!result[category]) {
            result[category] = [];
        }
        result[category].push(item);
        // result[InventoryCategory.ALL].push(item); 
    });
    return result;
};

const validateVideoLink = async (videoSrc) => {
    try {
        const response = await axios.head(videoSrc, { withCredentials: true, });
        if (response.status === 200) {
            return videoSrc; 
        } else {
            throw new Error('Invalid video link');
        }
    } catch (error) {
        console.error('Video link validation failed:', error);
        return '';  
    }
};

export const fetchReportsData = createAsyncThunk(
    'reports/fetchReportsData',
    async ({ rid }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${para_be}/reports/get_report_data?report_id=${rid}`, {
                withCredentials: true,
            });
            if (response.status >= 400) {
                return rejectWithValue(response.data.msg || 'Error fetching Reports data');
            }
            const result = response.data.result;
            const videoSrc = result[4].replace('.webm', '.mp4');
            const validatedVideoSrc = await validateVideoLink(videoSrc);
            return { ...response.data, validatedVideoSrc, rid };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

const validateVideoLinkMock = async (url) => url; 

export const fetchReportsDataToPreview = createAsyncThunk(
    "reports/fetchReportsData",
    async ({ rid }, { rejectWithValue }) => {
        try {
            // Simulate mock response
            const response = res;
            if (response.status >= 400) {
                return rejectWithValue(response.msg || "Error fetching Reports data");
            }
            const result = response.result;
            // Replace webm with mp4 and validate
            const videoSrc = result[4].replace(".webm", ".mp4");
            const validatedVideoSrc = await validateVideoLinkMock(videoSrc);
            return { ...response, validatedVideoSrc };
        } catch (error) {
            return rejectWithValue(error?.message || "Network error");
        }
    }
);

export const purchaseReport = createAsyncThunk(
    'reports/purchaseReport',
    async ({ rid }, { rejectWithValue }) => {
        try {      
            const response = await axios.post(`${para_be}/payments/purchase_report`, { rid }, {
                withCredentials: true,
            });
        
            if (response.status >= 400) {
                return rejectWithValue(response.data.msg || 'Failed to purchase the report.');
            }
            return response.data; 
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

export const checkIsReportPurchased = createAsyncThunk(
    'reports/checkIsReportPurchased',
    async ({ rid }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${para_be}/payments/is_report_purchased`, { rid }, {
                withCredentials: true,
            });
            if (response.status >= 400) {
                return rejectWithValue(response.data.msg || 'Failed to check report purchase status.');
            }
            return response.data.data; 
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

const filterData = (dataKey, orderedData, selectedCategories, checkboxStates, items, isPreviewMode) => (
    dataKey === ReportsCategory.INVENTORY ? (
        orderedData[dataKey].filter(
            (item) => (
                !selectedCategories || selectedCategories.length === 0 || selectedCategories.includes(item.category) || selectedCategories.includes(item?.room) || 
                items.includes(item.object_id)
            )
        ).filter((item) => !isPreviewMode || checkboxStates?.[dataKey]?.[item.object_id])
    ) : orderedData[dataKey].filter((item) => !isPreviewMode || checkboxStates?.[dataKey]?.[item.object_id])
);

export const EDIT_STATE = {
    START : "start",
    END: "end"
};

const defaultguestData = {
    "check-in": "",
    checkout: "",
    deposit: "",
    email: "",
    mobile: "",
    name: "",
    renter_id: "",
    tenancy_id: "",
    tenant_id: ""
};

const initialState = {
    data: [],
    loading: false,
    error: null,
    purchasedLoader: false, 
    isEmptyState: false,
    header: '',
    date: '',
    report_id: '',
    videoSrc: '',
    reportData: {},
    orderedData: {},
    filteredData: null,
    tabView: ReportsCategory.INVENTORY,
    pid: '',
    kitchenView: {},
    meterview: {},
    guestData: {},
    selectedCategories: null,
    inspectionType: null,
    hasBaseline: false,
    inventoryCategories: [],
    roomsCategories: [],
    roomsData: [],
    mediaData: {},
    checkboxStates: {},
    itemMetadata: {},
    includeVideoInExport: false,
    excludeCostFromExport: false,
    editExportMode: false,
    finishExportMode: false,
    previewMode: false,
    imageViewerModal: false,
    selectedImageIndex: null,
    saveEditData: null,
    linkModalOpen: { open: false },
    purchaseStatus: null, 
    receiptUrl: null,
    isLocked: false,
    commentModes: {},
    management: {},
    exportReport: {
        propertyRole: PROPERTY_ROLE.RESIDENT,
        exportName: '',
        allExportReports: [],
        currentExportReport: null,
        customText: '',
        selectedCurrency: "usd",
        existence: true
    },
    fetchExportReportLoding: false,
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        updateFilteredData: (state, action) => {
            const dataKey = action.payload;
            const items = (state.selectedCategories || []).reduce(
                (acc, category) => [
                    ...acc, 
                    ...((state.roomsData || []).length === 0 ? state.inventoryCategories : state.roomsCategories[dataKey])[category]
                ], []
            );
            state.filteredData = filterData(dataKey, state.orderedData, state.selectedCategories, state.checkboxStates, items, state.previewMode);
            state.tabView = dataKey;
        },
        toggleEditMode(state,action) {
            if (EDIT_STATE.START === action.payload) {
                state.editExportMode = true;
                state.finishExportMode = false;
            } else {
                state.finishExportMode = true;
            }
        },
        togglePropertyRole: (state, action) => {
            state.exportReport.propertyRole = action.payload;
        },
        toggleExportName: (state, action) => {
            state.exportReport.exportName = action.payload;
        },
        fetchAllExportReports: (state, action) => {
            state.exportReport.allExportReports = action.payload;
        },
        setManagementData: (state, action) => {
            state.management = action.payload;
        },
        setSelectedCategories: (state, action) => {
            state.selectedCategories = action.payload;
        },
        setCurrentExportExistence: (state, action) => {
            state.exportReport.existence = action.payload;
        },
        setCurrentExportReport: (state, action) => {
            state.exportReport.currentExportReport = action.payload;
            state.includeVideoInExport = action.payload?.exported_data?.includeVideo;
            state.excludeCostFromExport = action.payload?.exported_data?.excludeCost;
            state.finishExportMode = true;
        },
        setCurrentExportId: (state, action) => {
            if (state.exportReport.currentExportReport?.export_id) {
                state.exportReport.currentExportReport.export_id = action.payload;
            } else {
                state.exportReport.currentExportReport = {export_id: action.payload};
            }
        },
        setFetchExportReportLoding: (state, action) => {
            state.fetchExportReportLoding = action.payload;
        },
        setIncludeVideoInExport: (state, action) => {
            state.includeVideoInExport = action.payload;
        },
        setExcludeCostFromExport: (state, action) => {
            state.excludeCostFromExport = action.payload;
        },
        resetCurrentExportReport: (state, action) => {
            state.exportReport.currentExportReport = null;
        },
        setSelectedCurrency: (state, action) => {
            state.exportReport.selectedCurrency = action.payload;
        },
        resetEditMode(state) {
            state.finishExportMode = false
            state.editExportMode = false;
            state.checkboxStates = {};
            state.exportReport.customText = ''; 
            state.previewMode = false;
            if (state.exportReport.currentExportReport?.export_id) {
                state.exportReport.currentExportReport.export_id = undefined;
            }
        },
        reseItemMetadata(state) {
            state.itemMetadata = {};
            state.checkboxStates = {};
            state.exportReport.customText = '';
            state.exportReport.selectedCurrency = "usd"
            state.commentModes = {}
        },
        setPreviewMode(state, action) {
            state.previewMode = action.payload;
        },
        setCustomText(state, action) {
            state.exportReport.customText = action.payload;
        },
        setLinkModal(state, action) {
            state.linkModalOpen = action.payload;
        },
        setSelectedImageIndex(state, action) {
            state.selectedImageIndex = action.payload;
        },
        setSaveEditData(state, action) {
            state.selectedImageIndex = action.payload;
        },
        setEditExportMode(state, action) {
            state.editExportMode = action.payload;
        },
        setIsLocked(state, action) {
            state.isLocked = action.payload;
        },
        setCheckboxStates(state, action) {
            const { object_id, category, checked } = action.payload;
            const finalCategory = category || state.tabView;
            if (state.checkboxStates[finalCategory] === undefined) {
                state.checkboxStates[finalCategory] = {[object_id]: checked};
            } else {
                state.checkboxStates[finalCategory][object_id] = checked;
            }
            // state.checkboxStates[object_id] = checked;
        },
        setItemTitle(state, action) {
            const { object_id, category, title } = action.payload;
            const finalCategory = category || state.tabView;
            const m = { description: '', price: '', title: '' };
            if (!(state.itemMetadata[finalCategory] || {})[object_id]) {
                if (state.itemMetadata[finalCategory] === undefined) {
                    state.itemMetadata[finalCategory] = {[object_id]: m};
                } else {
                    state.itemMetadata[finalCategory][object_id] = m;
                }
            }
            state.itemMetadata[finalCategory][object_id].title = title;
            // if (!state.itemMetadata[object_id]) {
            //     state.itemMetadata[object_id] = { description: '', price: '', title: '' };
            // }
            // state.itemMetadata[object_id].title = title;
        },
        setItemDescription(state, action) {
            const { object_id, category, description } = action.payload;
            const finalCategory = category || state.tabView;
            const m = { description: '', price: '' };
            if (!(state.itemMetadata[finalCategory] || {})[object_id]) {
                if (state.itemMetadata[finalCategory] === undefined) {
                    state.itemMetadata[finalCategory] = {[object_id]: m};
                } else {
                    state.itemMetadata[finalCategory][object_id] = m;
                }
            }
            state.itemMetadata[finalCategory][object_id].description = description;
            // if (!state.itemMetadata[object_id]) {
            //     state.itemMetadata[object_id] = { description: '', price: '' };
            // }
            // state.itemMetadata[object_id].description = description;
        },
        setItemPrice(state, action) {
            const { object_id, category, price } = action.payload;
            const finalCategory = category || state.tabView;
            const m = { description: '', price: '' };
            if (!(state.itemMetadata[finalCategory] || {})[object_id]) {
                if (state.itemMetadata[finalCategory] === undefined) {
                    state.itemMetadata[finalCategory] = {[object_id]: m};
                } else {
                    state.itemMetadata[finalCategory][object_id] = m;
                }
            }
            state.itemMetadata[finalCategory][object_id].price = price;
            // if (!state.itemMetadata[object_id]) {
            //     state.itemMetadata[object_id] = { description: '', price: '' };
            // }
            // state.itemMetadata[object_id].price = price;
        },
        resert(state, action) {
            state.itemMetadata = {};
        },
        handleOpenModal(state,action){
            const index = action.payload;
            console.log("Opening modal for object index:", index);
            state.selectedImageIndex = index;
            state.imageViewerModal = true;
            console.log("Finished updated the states");
        },
        handleCloseModal(state,action) {
            state.imageViewerModal = false;
            state.selectedImageIndex = null;
        },
        toggleCommentMode: (state, action) => {
            const object_id = action.payload;
            state.commentModes[object_id] = state.commentModes[object_id] === 'Edit Comment' 
                ? 'Add Comment' 
                : 'Edit Comment';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReportsData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchReportsData.fulfilled, (state, action) => {
                state.loading = false;
                const { status, result, validatedVideoSrc, rid } = action.payload;
            
                if (status === 200 && result && result.length > 0) {
                    state.report_id = rid;
                    state.isEmptyState = false;
                    state.data = result;
                    state.header = result[0];
                    const xDate = result[1].split(" ")[0];
                    const xTime = result[1].split(" ")[1];
                    state.date = dateObjFormatter(
                        new Date(
                            "20" + xDate.split("/")[2] + "-" + xDate.split("/")[1] + "-" + 
                            xDate.split("/")[0] + "T" + xTime + "Z"
                        ), DATETIME_FORMAT
                    );
                    state.videoSrc = validatedVideoSrc;
                    state.hasBaseline = result[11] ? false : true;
                
                    let flatReportData = {};
                    if (result[11]) {
                        const {
                            inventory = [],
                            defects = []
                        } = result[5];
                        flatReportData = {
                            [ReportsCategory.INVENTORY]: inventory.map((item) => ({ ...item, label: item.label.replace("ade_", "").replace("m4_", "") })),
                            [ReportsCategory.DEFECTS]: defects,
                            inventorySize: inventory.length,
                            defectsSize: defects.length,
                        }
                    } else {
                        const { 
                            inventory = [], 
                            inventory_defects = [], 
                            organization = [], 
                            structural_defects = [] 
                        } = result[5]
                        flatReportData = {
                            [ReportsCategory.INVENTORY]: inventory.map((item) => ({ ...item, label: item.label.replace("ade_", "").replace("m4_", "") })),
                            [ReportsCategory.ORGANIZATION]: organization.map((item) => ({ ...item, label: item.label.replace("ade_", "").replace("m4_", "") })),
                            [ReportsCategory.DEFECTS]: [...inventory_defects, ...structural_defects],
                            inventorySize: inventory.length,
                            organizationSize: organization.length,
                            defectsSize: [...inventory_defects, ...structural_defects].length,
                        };
                    }
                    
                    const roomsData = result[14];
                    let roomsCategories = null;
                    if ((roomsData || []).length > 0) {
                        const roomsList = roomsData.reduce((acc1, r) => {
                            if (!acc1.includes(r.title)) acc1.push(r.title);
                            return acc1;
                        }, []);
                        roomsCategories = [
                            ReportsCategory.INVENTORY, ReportsCategory.ORGANIZATION, ReportsCategory.DEFECTS
                        ].reduce(
                            (acc1, key) => ({
                                ...acc1,
                                [key]: roomsList.reduce((acc2, r) => ({
                                    ...acc2, 
                                    [r]: (flatReportData?.[key] || []).reduce((acc3, item) => {
                                        if (item.room === r) acc3.push(item.object_id);
                                        return acc3;
                                    }, [])
                                }), {})
                            }), {}
                        );
                        state.roomsCategories = roomsCategories;
                    }
                    state.roomsData = roomsData;
                
                    state.reportData = flatReportData;
                    const orderedData = [
                        ReportsCategory.INVENTORY, ReportsCategory.ORGANIZATION, ReportsCategory.DEFECTS
                    ].reduce(
                        (acc1, key) => ({
                            ...acc1,
                            [key]: (
                                (
                                    roomsCategories === null || 
                                    (!result[11] && [ReportsCategory.INVENTORY, ReportsCategory.ORGANIZATION].includes(key))
                                ) ?
                                // key === ReportsCategory.INVENTORY ? 
                                [ReportsCategory.INVENTORY, ReportsCategory.ORGANIZATION].filter((o) => Object.keys(flatReportData).includes(o)).includes(key) ? 
                                    Object.keys(result[12]).reduce(
                                        (acc, k) => [
                                            ...acc, 
                                            ...result[12][k].map(
                                                (_id) => flatReportData[key].filter((o) => o.object_id === _id)[0]
                                            )
                                        ], []
                                    ) : flatReportData[key]
                                :
                                Object.keys(roomsCategories[key]).reduce((acc, k) => [
                                    ...acc, ...roomsCategories[key][k].map(
                                        (_id) => flatReportData[key].filter((o) => o.object_id === _id)[0]
                                    )
                                ], [])
                            )?.filter((o) => o != null)
                        }), {}
                    );    
                    state.orderedData = orderedData
                    state.filteredData = filterData(
                        ReportsCategory.INVENTORY, orderedData, null, 
                        state.checkboxStates, [], state.previewMode
                    );
                    state.pid = result[6];
                    state.kitchenView = result[7]['kitchen_view'];
                    state.meterview = result[7]['meter_view'];
                    state.guestData = {...defaultguestData,...result[8]};
                    state.inspectionType = result[10];
                    // API sends the opposite
                    state.inventoryCategories = result[12];
                    state.mediaData = result[13];
                } else {
                    state.isEmptyState = true;
                }
            })
            .addCase(fetchReportsData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(checkIsReportPurchased.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkIsReportPurchased.fulfilled, (state, action) => {
                state.loading = false;
                const { isPurchased } = action.payload;
                state.isLocked = !isPurchased; 
            })
            .addCase(checkIsReportPurchased.rejected, (state,action) => {
                state.loading = false;
                state.isLocked = true; 
                state.error = action.payload; 
            })
            .addCase(purchaseReport.pending, (state) => {
                state.purchasedLoader = true; 
                state.purchaseStatus = null; 
                state.error = null; 
                state.receiptUrl = null; 
            })
            .addCase(purchaseReport.fulfilled, (state, action) => {
                const { status, msg, receipt_url } = action.payload;
        
                state.purchasedLoader = false;
        
                if (status === 200) {
                    state.purchaseStatus = action.payload.msg; 
                    state.receiptUrl = action.payload.receipt_url
                } else if (status === 400) {
                    state.isLocked = false; 
                    state.purchaseStatus = msg; 
                } else {
                    state.purchaseStatus = "Unexpected response from the server.";
                }
            })
            .addCase(purchaseReport.rejected, (state, action) => {
                state.purchasedLoader = false; 
                state.error = action.payload.msg || "An error occurred."; 
            });
    }
});


export const { 
    toggleEditMode, resetEditMode, setCheckboxStates, setSelectedImageIndex, setIncludeVideoInExport, setExcludeCostFromExport, setSelectedCategories, updateFilteredData,
    setItemTitle, setItemDescription, setItemPrice, setCustomText, handleOpenModal,handleCloseModal, setManagementData,
    setPreviewMode, setEditExportMode, setLinkModal, setIsLocked, togglePropertyRole, toggleExportName,
    fetchAllExportReports, setCurrentExportReport, resetCurrentExportReport, setSelectedCurrency,
    reseItemMetadata, toggleCommentMode, setFetchExportReportLoding, setCurrentExportExistence, setCurrentExportId
} = reportsSlice.actions;
export default reportsSlice.reducer;