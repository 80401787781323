export default function savePMSCredentials(para_be, pmsType) {
    
    const fData = pmsFieldsDataOut[pmsType]();
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'withCredentials': true},
        body: JSON.stringify({"pms": pmsType, ...fData})
    };
    return fetch(para_be + '/settings/save_pms_creds', requestOptions)
    .then(response => response.json())
    .then(response => {
        if(response.status === 200) {
            return({"type":"success", "msg": "Connected"});
        } else {
            return({"type":"error", "msg": response.msg});
        }
    })
    .catch ( error => {
        console.log(error);
        return({"type":"error", "msg": "Connection failed"});
    })
}

export const acceptedPMS = {
    "arthur": "Arthur",
    "buildium": "Buildium",
    "propertyware": "Propertyware",
    "appfolio": "AppFolio",
    // "rent_manager": "Rent Manager",
    // "yardi": "Yardi",
    // "realpage": "RealPage",
    // "resman": "ResMan",
    // "entrata": "Entrata",
    // "rentec_direct": "Rentec Direct",
    // "tenant_cloud": "Tenant Cloud",
    // "property_boss": "Property Boss"
};

export const pmsFieldsData = {
    arthur: () => ({
        "client-id": document.getElementById("pms-client-id").value, 
        "secret-key": document.getElementById("client-secret-key").value,
        "entity": document.getElementById("entity").value
    }),
    buildium: () => ({
        "client-id": document.getElementById("pms-client-id").value, 
        "secret-key": document.getElementById("client-secret-key").value
    }),
    propertyware: () => ({
        "system-id": document.getElementById("pms-system-id").value, 
        "client-id": document.getElementById("pms-client-id").value, 
        "secret-key": document.getElementById("client-secret-key").value
    }),
};

export const pmsFieldsDataOut = {
    buildium: () => ({
        "client_id": document.getElementById("pms-client-id").value, 
        "secret_key": document.getElementById("client-secret-key").value
    }),
    appfolio: (e,p) => ({
        "client_id": e, 
        "secret_key": p
    }),
    propertyware: () => ({
        "system_id": document.getElementById("pms-system-id").value, 
        "client_id": document.getElementById("pms-client-id").value, 
        "secret_key": document.getElementById("client-secret-key").value
    }),
};

export const pmsFields = {
    arthur: (SimpleInput, HideTextInput, inputError) => <>
        <SimpleInput
            id={"pms-client-id"}
            type={"text"}
            label={"Client Id"}
            placeholder={"Enter PMS client id"}
            inputError={inputError?.hasOwnProperty("client-id") ? inputError["client-id"] : undefined}
        />
        <HideTextInput
            id={"client-secret-key"}
            label={"Client Secret Key"}
            placeholder={"Enter client secret key"}
            inputError={inputError?.hasOwnProperty("secret-key") ? inputError["secret-key"] : undefined}
        />
        <SimpleInput
            id={"entity"}
            type={"text"}
            label={"Entity Id"}
            placeholder={"Enter PMS Entity Id"}
            inputError={inputError?.hasOwnProperty("entity") ? inputError["entity"] : undefined}/>
    </>,
    buildium: (SimpleInput, HideTextInput, inputError) => <>
        <SimpleInput
            id={"pms-client-id"}
            type={"text"}
            label={"Client Id"}
            placeholder={"Enter PMS client id"}
            inputError={inputError?.hasOwnProperty("client-id") ? inputError["client-id"] : undefined}
        />
        <HideTextInput
            id={"client-secret-key"}
            label={"Client Secret Key"}
            placeholder={"Enter client secret key"}
            inputError={inputError?.hasOwnProperty("secret-key") ? inputError["secret-key"] : undefined}
        />
    </>,
    propertyware: (SimpleInput, HideTextInput, inputError) => <>
        <SimpleInput
            id={"pms-system-id"}
            type={"text"}
            label={"System Id"}
            placeholder={"Enter PMS system id"}
            inputError={inputError?.hasOwnProperty("system-id") ? inputError["client-id"] : undefined}
        />
        <SimpleInput
            id={"pms-client-id"}
            type={"text"}
            label={"Client Id"}
            placeholder={"Enter PMS client id"}
            inputError={inputError?.hasOwnProperty("client-id") ? inputError["client-id"] : undefined}
        />
        <HideTextInput
            id={"client-secret-key"}
            label={"Client Secret Key"}
            placeholder={"Enter client secret key"}
            inputError={inputError?.hasOwnProperty("secret-key") ? inputError["secret-key"] : undefined}
        />
    </>
};
