import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//slices
import { openModal } from '../../../../../../store/slices/searchSlice';
//components
import TableOptionsMenu from '../../../../TableWidget/components/TableOptionsMenu';
//assets
import DocsIcon from '../../../../icons/docsIcon';
import PlayIcon from '../../../../icons/playIcon';
import UploadIcon from '../../../../icons/uploadIcon';
import FileStack from '../../../../icons/fileStack';
import MaterialSymbols from '../../../../icons/materialSymbols';
import BuildingIcon from '../../../../icons/buildingIcon';
import ScanText from '../../../../icons/scanText';
//constants
import { MODAL_TYPES } from '../../../../../../constants';
import ShareScan from '../../../../TableWidget/components/TableView/components/ShareScan';


const OptionsMenu = React.memo(({ pid, subject, ext_ref, baseline_date }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const menuItems = [
        ...(baseline_date ? [
                {
                    label: 'Update Baseline Scan',
                    icon: ScanText,
                    onClick: () => history.push(`/${pid}/baseline-scan`),
                }
            ] : [
                {
                    label: 'Create Baseline Scan',
                    icon: BuildingIcon,
                    onClick: () => history.push(`/${pid}/baseline-scan`),
                }
            ]
        ),
        {
            label: 'Upload Baseline',
            icon: UploadIcon,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPLOADER_MODAL, pid })),
        },
        {
            label: 'All Reports',
            icon: FileStack,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.ALL_REPORTS_MODAL, subject, pid })),
        },
        {
            label: 'All Tenancies',
            icon: FileStack,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.ALL_TENANCIES_MODAL, subject, pid })),
        },
        {
            label: 'Edit Unit Address',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPDATE_UNIT_ADDRESS_MODAL, subject, pid, ext_ref })),
        },
        {
            label: 'Edit Reference ID',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPDATE_UNIT_REFERENCE_MODAL, subject, pid, ext_ref })),
        },
        {
            label: 'Remove Unit',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.DELETE_UNIT_MODAL, subject, pid, ext_ref })),
        },
    ];

    return <TableOptionsMenu items={menuItems} />;
});


const ActionsPanelUnits = ({ pid, subject, latest_report_id, changeSetNotifState, scan_id, ext_ref, baseline_date }) => {
    const dispatch = useDispatch();

    return (
        <>
            {baseline_date ? (
                    <>
                        <span
                            className={`cursor-pointer`}
                            onClick={(e) => {
                            e.stopPropagation();
                            dispatch(openModal({ modalName: 'videoModal', subject, pid }));
                            }}
                            title={"View Baseline Scan"}
                        >
                            <PlayIcon />
                        </span>
                    </>
                ) : (
                    <>
                        <span className='cursor-default'>
                            <PlayIcon disabled />
                        </span>
                    </>
                )
            }
            {latest_report_id ? (
                    <>
                        <Link to={`/report/${latest_report_id}`} className="cursor-pointer" title={"View Report"}>
                            <DocsIcon />
                        </Link>
                    </>
                ) : (
                    <>
                        <span className='cursor-default'>
                            <DocsIcon disabled />
                        </span>
                    </>
                )
            }

            <ShareScan
                pid={pid}
                subject={subject}
                changeSetNotifState={changeSetNotifState}
                scan_id={scan_id}
                ext_ref={ext_ref}
            />

            <OptionsMenu
                pid={pid}
                subject={subject}
                ext_ref={ext_ref}
                baseline_date={baseline_date}
            />
        </>
    );
};

export default ActionsPanelUnits;