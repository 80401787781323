import React, {useState} from "react";
//styles
import "../main_popups.css";
import "./connect_pms.css";
//components
import Btn from "../../buttons/standard/btn";
import Notification from "../../side_notification/side_notification";
import { SimpleInputDropdown } from "../../dropdowns/simple_dropdown/simple_dropdown";
import SimpleInput from "../../inputs/simple_input/input";
import HideTextInput from "../../inputs/hide_text_input/input";
import AppFolioLogin from "../pms/appfolio/popupLogin/popupLogin";
//utils
import { saveArthurPMSCredentials } from "../../../utils/pms_connections/arthur_connection"
import savePMSCredentials, { acceptedPMS, pmsFields, pmsFieldsData } from "../../../utils/pms_connections/pms_connections"
import { capitalizeString } from "../../../utils/main_utils";
import { renderPopupNewWindow } from "../../../utils/popup_utils";

export default function ConnectPms(props) {

    const [step, setStep] = useState(1);
    const [chosenPMS, setChosenPMS] = useState("");
    const [inputError, setInputError] = useState(null);
    const [notif, setNotif] = useState(false);

    const dropdownItems = Object.keys(acceptedPMS).reduce((acc, item) => ({...acc, [item]: {'present': acceptedPMS[item]}}), {});
    
    const handleConnectPMS = (pmsType) => {
        if (Object.keys(pmsFields).includes(pmsType)) {
            setChosenPMS(pmsType);
            setStep(2);
        } else if (pmsType === "appfolio") {
            setChosenPMS(pmsType);
            renderPopupNewWindow(
                "AppFolio Login", AppFolioLogin, 
                () => {
                    setStep(1);
                    setNotif({msg: "Failed to login to AppFolio", type: "error"});
                },
                (formData) => {
                    handleConnectToPMS(formData);
                }
            );
            setStep(2);
        } else {
            //TODO - add other PMS types here if logic differs
        }
    };

    const handleConnectToPMS = (d) => {
        if (chosenPMS === "appfolio") {
            savePMSCredentials(props.para_be, "appfolio")
            .then(response => setNotif(response));
            return;
        }

        let fData = pmsFieldsData[chosenPMS]();
        // let fData = {"client-id": document.getElementById("pms-client-id").value, "secret-key": document.getElementById("client-secret-key").value};
        // if (chosenPMS === "arthur") fData['entity'] = document.getElementById("entity").value;
        let _inputErrors = {}
        for (let k in fData) {
            if (!fData[k] || fData[k].length === 0) {
                _inputErrors[k] = "Please enter " + capitalizeString(fData[k]);
            }
        }
        if (_inputErrors.length > 0) {
            setInputError(_inputErrors);
            return;
        }
        if (chosenPMS === "arthur") {
            saveArthurPMSCredentials(props.para_be)
            .then(response => setNotif(response));
        } else if (chosenPMS === "buildium") {
            savePMSCredentials(props.para_be, "buildium")
            .then(response => setNotif(response));
        }
    };

    return (
        <section className="pms-container">
            <div className="h2 blue-headline">Connect your PMS</div>
            <div className="pms-body-container">
                {step === 1 ? 
                    <div className="pms-step pms-step1">
                        <SimpleInputDropdown
                            label={"PMS Provider"}
                            items={dropdownItems}
                            onclick={(item, e) => handleConnectPMS(item)}/>
                    </div> :
                    chosenPMS === "appfolio" ? 
                        <>
                            <p>
                                Waiting for response from the popup window.
                            </p>
                        </> :
                        <div className="pms-step pms-step2">
                            <div>
                                {
                                    pmsFields[chosenPMS](SimpleInput, HideTextInput, inputError)
                                }
                            </div>
                            <Btn
                                type="primary"
                                text="Connect to PMS"
                                onclick={handleConnectToPMS}/>
                        </div>
                }
            </div>
            {notif ?
                <Notification
                    closeFunc={()=>setNotif(false)}
                    text={notif.msg}
                    type={notif.type}/> : ""
            }
        </section>
    )
}