import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from '@headlessui/react';
import { BellIcon, XMarkIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { fetchAllNotifications, fetchNewNotifications, markNotificationAsRead, deleteNotification, clearError } from '../../../../store/slices/notificationSlice';
import { useHistory } from 'react-router-dom';
import { daysAgo } from '../../../../utils/date_utils';
import EmptyState from '../../../emptyState/empty_state';
import CircularProgress from '../../../loaders/CircularLoader';
import SnackBar from '../../../SnackBar';
import { Notifications } from '../../../../utils/enums_utils';
import { para_be, base_url } from '../../../../config';
import { postReqOptBuilder } from '../../../../utils/main_utils';
import alert from '../../../../assets/notifications/alert.svg';
import newReport from '../../../../assets/notifications/new-report.svg';
import comment from '../../../../assets/notifications/comment.svg';

const BellNotification = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { notifications, loading, error, allNotificationAsRead, currentNotifAmount } = useSelector(state => state.notifications);

    useEffect(() => {
        dispatch(fetchAllNotifications());
    }, [dispatch]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(fetchNewNotifications());
        }, 60000); 

        return () => clearInterval(intervalId); 
    }, [dispatch]);

    const handleDeleteNotification = (event, id) => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(deleteNotification(id)).unwrap()
        .then(() => {
            dispatch(fetchAllNotifications());
        })
        .catch((error) => {
            console.error("Failed to mark notification as read:", error);
        });

    };

    const handleReadNotification = (id) => {
        dispatch(markNotificationAsRead({ id, readValue: 1 }))
            .unwrap()
            .then(() => {
                dispatch(fetchAllNotifications());
            })
            .catch((error) => {
                console.error("Failed to mark notification as read:", error);
            });
    };

    const handleRelation = (relation) => {
        fetch(`${para_be}/notifications/display_notif`, postReqOptBuilder({ relation }))
            .then((response) => response.json())
            .then((response) => {
                if (response.status === 200) {
                    const displayNotif = response.result;
                    const currentPath = window.location.pathname;

                    let targetPath;

                    switch (displayNotif.relation) {
                        case Notifications.N_NEW_COMMENT:
                            targetPath = `${base_url}${displayNotif.path}#ci_id=${displayNotif.ci_id}&comment_id=${displayNotif.comment_id}`;
                            break;
                        case Notifications.N_NEW_UNIT:
                            targetPath = `${base_url}${displayNotif.path}#subject=${displayNotif.subject}`;
                            break;
                        case Notifications.N_NEW_REPORT:
                            targetPath = `${base_url}${displayNotif.path}`;
                            break;
                        default:
                            console.log("Unknown relation type:", displayNotif.relation);
                            return;
                    }

                    if (currentPath !== displayNotif.path) {
                        history.push(targetPath); 
                    } else {
                        history.replace(targetPath); 
                    }
                } else {
                    dispatch(clearError());
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(clearError());
            });
    };

    const iconType = (notifType) => {
        let iconUrl = null;
        let title = null;

        switch (notifType) {
            case 'new_rep':
                iconUrl = newReport;
                title = 'New Report';
                break;
            case 'new_com':
                iconUrl = comment;
                title = 'New Comment';
                break;
            case 'miss_base':
                iconUrl = alert;
                title = 'Missing Baseline Scan';
                break;
            case 'completed_tasks':
                iconUrl = newReport;
                title = 'All tasks have been completed';
                break;
            case 'user_register':
                iconUrl = newReport;
                title = 'New user registered';
                break;
            case 'new_unit':
                iconUrl = newReport;
                title = 'New unit has been added';
                break;
            case 'task_completed':
                iconUrl = newReport;
                title = 'All tasks have been completed';
                break;
            default:
                iconUrl = alert;
        }
        return [iconUrl, title];
    };

    const handleCloseNotif = () => {
        dispatch(clearError());
    };

    return (
        <>
            {/* Gray circle wrapper for the Bell Icon */}
            <div className="relative flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
                <Menu as="div" className="flex-shrink-0">
                    <Menu.Button className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                        <span className="sr-only">Open options</span>
                        <BellIcon aria-hidden="true" className="h-6 w-6 text-gray-800" />
                        {currentNotifAmount > 0 && (
                            <span className="absolute top-0 right-0 flex h-4 w-4 items-center justify-center rounded-full bg-indigo-400 text-white text-xs">
                                {currentNotifAmount < 10 ? currentNotifAmount : '9+'}
                            </span>
                        )}
                    </Menu.Button>
                    <Menu.Items
                        transition
                        className="absolute overflow-auto right-0 w-80 h-96 top-11 z-10 mx-3 mt-1 bg-gray-100 divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                    >
                        <div className="flex justify-between p-4">
                            <div className="text-sm font-extrabold text-indigo-700">Notification</div>
                            <div className="text-sm font-extrabold text-indigo-700 hover:text-indigo-400 cursor-pointer" onClick={() => handleReadNotification()}>
                                Mark all as read
                            </div>
                        </div>

                        <ul role="list" className="grid grid-cols-1">
                            {loading ? (
                                <div className='flex justify-center items-center h-72'>
                                    <CircularProgress size={24} />
                                </div>
                                
                            ) : (
                                notifications &&
                                notifications.map((item) => {
                                    const type_data = iconType(item[4]);
                                    return (
                                        <li key={item[0]} onClick={() => handleRelation(item[5])}>
                                            <div
                                                className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition"
                                            >
                                                <div className="p-4">
                                                    <div className="flex items-start">
                                                        <div className="flex-shrink-0">
                                                            <img className="h-3 w-3 mt-1" src={type_data[0]} alt="notif-type"/>
                                                        </div>
                                                        <div className="ml-3 w-0 flex-1 pt-0.5">
                                                            <div className="flex items-center space-x-2 truncate">
                                                                <h3 className="text-xs font-medium text-gray-900">{daysAgo(item[6])}</h3>
                                                                <span className="inline-flex text-xs flex-shrink-0 items-center rounded-full bg-indigo-400 px-1.5 py-0.5 text-white ring-1 ring-inset ring-green-600/20">
                                                                    {item[2]}
                                                                </span>
                                                            </div>
                                                            <div className="flex mt-2">
                                                                <span className="text-xs text-gray-500">{item[3]}</span>
                                                                {allNotificationAsRead && (
                                                                    <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-indigo-400" />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="ml-4 flex flex-shrink-0">
                                                            <button
                                                                type="button"
                                                                onClick={(event) => handleDeleteNotification(event, item[0])}
                                                                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            >
                                                                <span className="sr-only">Close</span>
                                                                <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                            )}
                        </ul>
                        {notifications && notifications.length === 0 && !loading && <EmptyState text="You currently have no notifications" size="md" />}
                        
                    </Menu.Items>
                </Menu>
            </div>
           
            {error && (
                <SnackBar open={Boolean(error)} handleClose={handleCloseNotif} message={error} duration={6000} />
            )}
        </>
    );
};

export default BellNotification;