import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
//mui
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';


const VideoPlayer = ({ videoSrc, videoHighlights=[] }) => {
    const isMobile = useSelector((state) => state.config?.isMobile);
    
    const [isPlaying, setIsPlaying] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isFullscreen, setIsFullScreen] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const videoRef = useRef(null);
    const videoContainerRef = useRef(null);
    
    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };
    
    const handlePause = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const toggleFullscreen = () => {
        if (isFullscreen) {
            document.exitFullscreen();
        } else {
            videoContainerRef.current.requestFullscreen();
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60).toString().padStart(2, '0');
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };
        const handleTimeUpdate = () => setCurrentTime(videoRef.current.currentTime);
        const handleLoadedMetadata = () => setDuration(videoRef.current.duration);
        const handlePlayEvent = () => setIsPlaying(true);
        const handlePauseEvent = () => setIsPlaying(false);
        
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('play', handlePlayEvent);
            videoElement.addEventListener('pause', handlePauseEvent);
            videoElement.addEventListener('timeupdate', handleTimeUpdate);
            videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
            document.addEventListener('fullscreenchange', handleFullscreenChange);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('play', handlePlayEvent);
                videoElement.removeEventListener('pause', handlePauseEvent);
                videoElement.removeEventListener('timeupdate', handleTimeUpdate);
                videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
                document.removeEventListener('fullscreenchange', handleFullscreenChange);
            }
        };
    }, []);
    
    
    return (
        <div
            ref={videoContainerRef}
            className={`relative ${isMobile ? 'w-full h-96' : 'w-64 h-56'} bg-gray-200 rounded-lg overflow-hidden`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {/* Video Element */}
            <video
                ref={videoRef}
                src={videoSrc}
                // controls
                muted
                playsInline
                onClick={isPlaying ? handlePause : handlePlay}
                className={`w-full h-full ${isFullscreen ? 'object-contain' : 'object-cover'}`}
            >
                <p>The video is not available or is not supported by your browser</p>
            </video>
            
            {/* Play/Pause Button */}
            {(!isPlaying || (isPlaying && isHovered)) && (
                <button
                    onClick={isPlaying ? handlePause : handlePlay}
                    className="absolute inset-0 m-auto flex items-center justify-center bg-purple-600 rounded-full w-12 h-12 hover:bg-purple-700 transition"
                >
                    {isPlaying ? (
                            <PauseIcon className="h-6 w-6 text-white" />
                        ) : (
                            <PlayArrowIcon className="h-6 w-6 text-white" />
                        )
                    }
                </button>
            )}

            {/* Custom Controls Overlay */}
            <div className='absolute bottom-0 left-0 w-full flex flex-col items-center bg-black bg-opacity-50 py-2 rounded-lg'>
                <div className='flex justify-between items-center w-full px-4 gap-x-1'>
                    <button onClick={isPlaying ? handlePause : handlePlay} className='text-white text-xl' style={{height: '24px', width: '24px', marginTop: '-8px'}}>
                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </button>
                    
                    <span className="text-white text-xs whitespace-nowrap">{formatTime(currentTime)} / {formatTime(duration)}</span>
                    
                    {/* Highlight Timeline & Seek Bar */}
                    <div className='px-4 w-full mt-1'>
                        <div className='relative w-full'>
                            <input
                                type="range"
                                min="0"
                                max={duration}
                                value={currentTime}
                                onChange={(e) => videoRef.current.currentTime = e.target.value}
                                className='w-full cursor-pointer relative'
                            />
                            {videoHighlights.map(({ start, end, title }, index) => {
                                const initStartSeconds = start.split(':').reduce((acc, v, idx) => acc + parseInt(v) * (idx === 0 ? 60 : idx === 1 ? 1 : 0), 0);
                                const endSeconds = end.split(':').reduce((acc, v, idx) => acc + parseInt(v) * (idx === 0 ? 60 : idx === 1 ? 1 : 0), 0);
                                const isShowing = currentTime >= initStartSeconds && currentTime <= endSeconds;
                                const startSeconds = isShowing ? currentTime : initStartSeconds;
                                return (
                                    <div
                                        key={index}
                                        className='absolute bg-yellow-500 opacity-75 cursor-pointer tooltip'
                                        style={{
                                            left: `${(startSeconds / duration) * 100}%`,
                                            width: isShowing ? `${((endSeconds - startSeconds) / duration) * 100}%` : '4px',
                                            height: "8px",
                                            top: "6px"
                                        }}
                                        title={title}
                                        onClick={() => videoRef.current.currentTime = initStartSeconds}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <button onClick={toggleFullscreen} className='text-white text-xl' style={{height: '24px', width: '24px', marginTop: '-8px'}}>
                        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </button>
                    <a href={videoSrc} download target="_blank" className='text-white text-xl' style={{height: '24px', width: '24px', marginTop: '-8px'}}>
                        <DownloadIcon />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayer;