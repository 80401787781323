import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//mui
import { Box, Typography, Chip } from '@mui/material';
//components
import VideoPlayer from './components/VideoPlayer';
import PreviousScansDropDown from './components/PreviousScansDropDown';
import GuestDetails from './components/GuestDetails';
import Android12Switch from '../../../../components/switch/Android12Switch';
import PropertyManagementCard from './components/PropertyManagementCard';
import AlertBaseline from './components/AlertBaseline';
//assets
import DateIcon from '../../icons/dateIcon';
import PaperIcon from '../../icons/paperIcon';
import BuildingIcon from '../../../../components/Tables/icons/buildingIcon';
//slices
import { setExcludeCostFromExport, setIncludeVideoInExport } from '../../../../store/slices/reportsSlice';
//constants
import { PROPERTY_ROLE } from '../../../../constants';
import { capitalizeString } from '../../../../utils/main_utils';

const ReportDetails = ({ rid, handleNotifState }) => {
    const dispatch = useDispatch();
    const {
        videoSrc,
        header,
        date,
        inspectionType,
        previewMode,
        exportReport,
        hasBaseline,
        roomsData,
        editExportMode,
        includeVideoInExport,
        excludeCostFromExport,
    } = useSelector((state) => state.reports);
    const isMobile = useSelector((state) => state.config?.isMobile);
    
    // const [isChecked, setIsChecked] = useState(includeVideoInExport);
    
    const inspectionTitle = () => {
        if (inspectionType === 'move_in' || inspectionType === "checkin") return 'Move In';
        if (inspectionType === 'move_out' || inspectionType === "checkout") return 'Move Out';
        if (inspectionType === 'semi_annual') return 'Semi-Annual';
        if (inspectionType === 'annual') return 'Annual';
        if (inspectionType === 'turn') return 'Turn';
        if (inspectionType === 'periodical') return 'Periodical';
        if (inspectionType) return capitalizeString(inspectionType.split("_").join(" "), true);
        return 'Move Out';
    }

    // useEffect(() => {
    //     setIncludeVideoInExport(isChecked);
    // }, [isChecked]);
    
    
    return (
        <div className={`pt-5 pr-5 pl-5 ${(!hasBaseline && !previewMode) ? 'pb-5' : ''} bg-white rounded-lg shadow-md`} style={{ border: '1px solid #F2F2F3' }}>
            <div className={`${isMobile ? 'flex flex-col gap-4' : 'flex gap-4'}`}>
                <div className="flex flex-col items-center">
                    {(!previewMode || exportReport.currentExportReport?.exported_data?.includeVideo) && 
                        <VideoPlayer videoSrc={videoSrc} videoHighlights={roomsData} />
                    }
                    {!previewMode && <PreviousScansDropDown rid={rid} handleNotifState={handleNotifState} />}
                </div>
                
                <div className={`flex w-full ${isMobile ? 'flex-col' : 'justify-between'}`}>
                    <div className="flex flex-col space-y-4">
                        <Typography sx={{ fontSize: '20px', color: '#0C1222', fontWeight: 500 }}>
                            {`${inspectionTitle()} Inspection Report`}
                        </Typography>
                        <div className="flex flex-wrap gap-2 pb-6" style={{ borderBottom: '1px solid #F2F2F3' }}>
                            <Chip
                                icon={
                                    <div>
                                        <BuildingIcon stroke="#918BEF" />
                                    </div>
                                }
                                label={`${header}`}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: '#0C1222',
                                    paddingLeft: '8px',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    display: 'flex',
                                    alignItems: 'center',
                                    '.MuiChip-label': { paddingLeft: '3px' },
                                    '.MuiChip-icon': {
                                        padding: '5px',
                                        marginLeft: '0',
                                        marginRight: '5px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        backgroundColor: '#DCE2E5',
                                        borderRadius: '50%',
                                    }
                                }}
                            />
                            <Chip
                                icon={
                                    <div>
                                        <DateIcon />
                                    </div>
                                }
                                label={`Date: ${date}`}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: '#0C1222',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    paddingLeft: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    '.MuiChip-label': { paddingLeft: '3px' },
                                    '.MuiChip-icon': {
                                        padding: '5px',
                                        marginLeft: '0',
                                        marginRight: '5px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        backgroundColor: '#DCE2E5',
                                        borderRadius: '50%',
                                    }
                                }}
                            />
                        </div>
                        <div className="flex flex-col">
                            <GuestDetails />
                            {(!previewMode && editExportMode) && (
                                <Box sx={{ mb: 2, mt: 4}}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Android12Switch checked={includeVideoInExport} onChange={(e) => dispatch(setIncludeVideoInExport(e.target.checked))} />
                                        <Typography sx={{ color: '#0C1222', fontSize: '14px', fontWeight: 500 }}>
                                            Include video in report
                                        </Typography>
                                    </Box>

                                    {exportReport.propertyRole === PROPERTY_ROLE.RESIDENT &&
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Android12Switch checked={excludeCostFromExport} onChange={(e) => dispatch(setExcludeCostFromExport(e.target.checked))} />
                                            <Typography sx={{ color: '#0C1222', fontSize: '14px', fontWeight: 500 }}>
                                                Exclude cost from report
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                            )}
                        </div>
                    </div>
                    {(previewMode || editExportMode) && <PropertyManagementCard />}
                </div>
            </div>

            <div className="mt-6">
                {(!hasBaseline && !previewMode) && <AlertBaseline handleNotifState={handleNotifState} rid={rid} />}
            </div>
        </div>
    );
};

export default ReportDetails;